<template>
  <f7-page name="home" @page:init="pageInit">
    <f7-popover class="popover-credit">
      <f7-block-title medium
        >{{ credit }} {{ $t("credits-available") }}
      </f7-block-title>
      <f7-block>
        <i v-text="$t('credits-collection')"> </i>
      </f7-block>
      <f7-block-title>{{ $t("credits-how-to") }}</f7-block-title>
      <f7-list class="credit-opportunities" no-hairlines no-hairlines-between>
        <ul>
          <creditlist
            v-for="(event, index) in events"
            :key="index"
            v-bind:index="index"
            v-bind:title="event"
            v-bind:checked="completed && completed.includes(index)"
          />
        </ul>
      </f7-list>
    </f7-popover>

    <debugpopover
      v-if="this.apiProjectId != 'thumbify-me'"
      v-bind:user="user"
      v-bind:apiProjectId="apiProjectId"
      v-bind:currentProject="currentProject"
      v-bind:session="session"
    />

    <f7-card class="main-card" padding>
      <f7-card-content>
        <f7-row class="card-nav">
          <f7-col width="100" medium="30">
            <f7-link href="/" @click="clearProject(0)">
              <img
                src="static/images/logo.svg"
                class="logo"
                alt="thumbify.me logo"
              />
            </f7-link>
          </f7-col>
          <f7-col
            width="10"
            :class="wizardStep == 1 ? 'current' : ''"
            v-if="wizardStep > 0"
          >
            <f7-button
              @click="nextWizardStep(1)"
              :class="currentProject.videoUrl ? 'completed' : ''"
            >
              {{ $t("menu.start") }}
              <div class="nav-ring">1</div>
            </f7-button>
          </f7-col>
          <f7-col
            width="10"
            :class="wizardStep == 2 ? 'current' : ''"
            v-if="wizardStep > 0"
          >
            <f7-button
              @click="nextWizardStep(2)"
              :class="canvas && canvas.background ? 'completed' : ''"
            >
              {{ $t("menu.background") }}
              <div class="nav-ring">2</div>
            </f7-button>
          </f7-col>
          <f7-col
            width="10"
            :class="wizardStep == 3 ? 'current' : ''"
            v-if="wizardStep > 0"
          >
            <f7-button
              @click="nextWizardStep(3)"
              :class="images && images.length ? 'completed' : ''"
            >
              {{ $t("menu.personalize") }}
              <div class="nav-ring">3</div>
            </f7-button>
          </f7-col>
          <f7-col
            width="10"
            :class="wizardStep == 4 ? 'current' : ''"
            v-if="wizardStep > 0"
          >
            <f7-button
              @click="nextWizardStep(4)"
              :class="
                textTitle.text || currentProject.playImageId ? 'completed' : ''
              "
            >
              {{ $t("menu.addons") }}
              <div class="nav-ring">4</div>
            </f7-button>
          </f7-col>
          <f7-col
            width="10"
            :class="wizardStep == 5 ? 'current' : ''"
            v-if="wizardStep > 0"
          >
            <f7-button
              @click="nextWizardStep(5)"
              :class="currentProject && currentProject.title ? 'completed' : ''"
            >
              {{ $t("menu.sharing") }}
              <div class="nav-ring">5</div>
            </f7-button>
          </f7-col>

          <f7-col width="10" v-if="!isMobile">
            <f7-button class="user-credit" popover-open=".popover-credit">
              <f7-icon icon="icon-thumbify"></f7-icon>
              <number
                ref="number1"
                :from="0"
                :to="credit"
                :duration="2"
                easing="Power1.easeOut"
              />
              <div class="score">{{ $t("menu.credits") }}</div>
            </f7-button>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col
            width="100"
            v-if="
              wizardStep == 1 && showLinkOptions && !currentProject.videoUrl
            "
            class="start-box"
          >
            <div>
              <f7-link @click="nextWizardStep('+')">
                <video v-show="!showExplainer" autoplay loop muted>
                  <source src="static/video/scratch.mp4" type="video/mp4" />
                </video>
                <span>Build thumbnail from scratch</span>
              </f7-link>
            </div>
            <div>
              <f7-link @click="showLinkOptions = false">
                <video v-show="!showExplainer" autoplay loop muted>
                  <source src="static/video/fromvideo.mp4" type="video/mp4" />
                </video>
                <span>Build thumbnail from existing video</span>
              </f7-link>
            </div>
          </f7-col>
          <f7-col
            width="100"
            v-if="
              wizardStep == 1 && (!showLinkOptions || currentProject.videoUrl)
            "
          >
            <div class="video-box">
              <f7-block-title>
                {{ $t("video-link-title") }}
                <f7-link
                  icon="icon-help"
                  class="sample-icon"
                  @click="
                    currentProject.videoUrl =
                      'https://www.youtube.com/watch?v=EEZES7hu-6E';
                    fetchVideo();
                  "
                ></f7-link>
              </f7-block-title>
              <p class="additional">
                {{ $t("video-link-description") }}
              </p>
              <f7-list no-hairlines>
                <f7-list-input
                  floating-label
                  outline
                  :label="$t('video-url')"
                  type="url"
                  tabindex="2"
                  placeholder="ex. https://www.youtube.com/watch?v=EEZES7hu-6E"
                  :value="currentProject.videoUrl"
                  :disabled="videoLoading"
                  @change="fetchVideo"
                  clear-button
                >
                </f7-list-input>
              </f7-list>
              <p class="additional">
                {{ $t("video-link-note") }}
              </p>
              <f7-link @click="nextWizardStep('+')">
                {{ $t("video-link-skip") }} &raquo;</f7-link
              >
            </div>
          </f7-col>
          <f7-col width="35" large="33" v-if="wizardStep == 2">
            <f7-navbar class="sub-nav">
              <f7-block-title> {{ $t("select-background") }} </f7-block-title>
              <f7-subnavbar>
                <f7-segmented raised>
                  <f7-button tab-link="#basic-tab" tab-link-active>
                    {{ $t("basic") }}
                  </f7-button>
                  <f7-button tab-link="#fun-tab"> {{ $t("fun") }} </f7-button>
                  <f7-button tab-link="#custom-tab">
                    {{ $t("custom") }}
                  </f7-button>
                </f7-segmented>
              </f7-subnavbar>
            </f7-navbar>
            <f7-tabs class="background-box">
              <f7-tab id="basic-tab" tab-active class="page-content">
                <f7-row>
                  <f7-col
                    width="50"
                    class="image-background tooltip-init"
                    @click="setBackground('video')"
                    v-if="videoImage && !videoLoading"
                  >
                    <div
                      class="cover tooltip-init"
                      :style="`background-image:url(` + videoImage + `)`"
                      :data-tooltip="'Video snapshot'"
                    />
                  </f7-col>
                  <f7-col width="50" class="video-loader" v-if="videoLoading">
                    <f7-preloader :size="20"></f7-preloader>
                    <i>{{ $t("loading-video") }}</i>
                    <div class="video-progress"></div>
                  </f7-col>
                  <backgroundlist
                    v-for="(title, index) in backgroundsBasic"
                    v-once
                    :key="index"
                    v-bind:title="title"
                    v-bind:index="index"
                    v-on:setBackground="setBackground"
                  />
                </f7-row>
              </f7-tab>
              <f7-tab id="fun-tab" class="page-content">
                <f7-row>
                  <backgroundlist
                    v-for="(title, index) in backgroundsFun"
                    v-once
                    :key="index"
                    v-bind:title="title"
                    v-bind:index="index"
                    v-on:setBackground="setBackground"
                  />
                </f7-row>
              </f7-tab>
              <f7-tab id="custom-tab" class="page-content">
                <f7-row>
                  <f7-col width="50" class="image-background upload-background">
                    <f7-button
                      class="cover"
                      icon="icon-folder"
                      @click="launchFilePicker('background')"
                      style="
                        background-image: url(./static/images/upload-background.png);
                      "
                    >
                      {{ $t("upload-background") }}
                    </f7-button>
                  </f7-col>
                  <f7-col width="50" class="image-background upload-background">
                    <f7-button
                      class="cover"
                      icon="icon-picture"
                      @click="launchBGLibrary()"
                    >
                      <div
                        class="background-overlay"
                        :style="
                          `background-image: url(` +
                          (uploadBackgroundImage
                            ? uploadBackgroundImage
                            : `./static/images/background-library.png`) +
                          `)`
                        "
                      ></div>
                      <div class="background-title">
                        {{ $t("show-background-library") }}
                      </div>
                    </f7-button>
                  </f7-col>
                </f7-row>

                <f7-list no-hairlines>
                  <f7-list-input
                    label="Blur"
                    :input="false"
                    v-if="canvas.background"
                  >
                    <f7-range
                      slot="input"
                      :value="backgroundBlur"
                      :min="0"
                      :max="20"
                      :step="1"
                      @range:change="setBlur"
                    ></f7-range>
                  </f7-list-input>
                </f7-list>
              </f7-tab>
            </f7-tabs>
          </f7-col>
          <f7-col width="35" large="33" v-if="wizardStep == 3">
            <f7-block-title> {{ $t("upload-photo-title") }} </f7-block-title>
            <f7-block>
              <!-- <f7-link
                  href="https://towardsdatascience.com/background-removal-with-deep-learning-c4f2104b3157"
                  external
                  style="color:#666666;"
                >  -->
              <p
                class="upload-photo-description"
                v-html="$t('upload-photo-description')"
              ></p>
              <!-- </f7-link> -->
              <!-- <f7-list no-hairlines no-hairlines-between class="upload-photo-outline">
                <f7-list-item
                  checkbox
                  :title="$t('add-image-outline')"
                  :checked="addOutline"
                  @change="addOutline = $event.target.checked"
                ></f7-list-item>
              </f7-list> -->
              <f7-row>
                <f7-col>
                  <div
                    class="image-upload"
                    @click="launchFilePicker('photo')"
                    v-if="!determinateUploadLoading"
                  >
                    <img :src="previewUploadImage" v-if="previewUploadImage" />
                    <img
                      src="static/images/upload.png"
                      class="button-image"
                      v-if="!previewUploadImage"
                    />
                  </div>
                  <div
                    class="image-scanning"
                    v-if="determinateUploadLoading"
                    style="background-image: url(./static/images/scan.gif)"
                  >
                    <img :src="previewUploadImage" v-if="previewUploadImage" />
                  </div>
                </f7-col>
                <f7-col>
                  <div
                    class="image-webcam"
                    v-if="!determinateWebcamLoading"
                    @click="launchWebcam"
                  >
                    <div class="image-webcam-bg" v-if="webcamImage">
                      <img :src="webcamImage" />
                    </div>
                    <img
                      src="static/images/webcam.png"
                      class="button-image"
                      v-if="!webcamImage"
                    />
                  </div>
                  <div
                    class="image-scanning"
                    v-if="determinateWebcamLoading"
                    style="background-image: url(./static/images/scan.gif)"
                  >
                    <img :src="previewWebcamImage" v-if="previewWebcamImage" />
                  </div>
                </f7-col>
                <f7-col>
                  <div class="image-library" @click="launchLibrary()">
                    <img src="static/images/library.png" class="button-image" />
                  </div>
                </f7-col>
              </f7-row>
              <f7-row class="photo-buttons">
                <f7-col>
                  <f7-button @click="launchFilePicker('photo')">
                    {{ $t("upload-image") }}
                  </f7-button>
                </f7-col>
                <f7-col>
                  <creditchip
                    eventName="used-webcam"
                    :events="events"
                    aligned="right"
                    v-if="completed && !completed.includes('used-webcam')"
                  ></creditchip>
                  <f7-button @click="launchWebcam">
                    {{ $t("use-webcam") }}
                  </f7-button>
                </f7-col>
                <f7-col>
                  <f7-button @click="launchLibrary()">
                    {{ $t("show-photo-library") }}
                  </f7-button>
                </f7-col>
              </f7-row>
            </f7-block>

            <div>
              <f7-block-title>
                {{ $t("select-company-title") }}
              </f7-block-title>
              <f7-list class="company-search" no-hairlines>
                <creditchip
                  eventName="add-company-logo"
                  :events="events"
                  aligned="right"
                  v-if="completed && !completed.includes('add-company-logo')"
                ></creditchip>
                <f7-list-input
                  floating-label
                  outline
                  :label="$t('company-name-domain')"
                  type="search"
                  placeholder="ex. Google, GetAccept, benjerry.com"
                  :value="currentProject.companyDomain"
                  input-id="autocomplete-company"
                  :disabled="websiteLoading"
                  ref="autocompleteCompany"
                  tabindex="1"
                  autocomplete="off"
                >
                </f7-list-input>
              </f7-list>
              <f7-block>
                <f7-row>
                  <f7-col
                    width="50"
                    class="image-logo"
                    @click="addLogo()"
                    v-if="logoImage && !logoLoading"
                  >
                    <img :src="logoImage" />
                  </f7-col>
                  <f7-col
                    width="50"
                    class="image-website tooltip-init"
                    @click="addWebsite()"
                    v-if="websiteImage && !websiteLoading"
                    :data-tooltip="currentProject.companyDomain"
                  >
                    <img :src="websiteImage" />
                  </f7-col>
                  <f7-col width="50" class="video-loader" v-if="logoLoading">
                    <f7-preloader :size="20"></f7-preloader>
                    <i>{{ $t("loading-logo") }}</i>
                  </f7-col>
                  <f7-col
                    width="50"
                    class="website-loader"
                    v-if="websiteLoading"
                  >
                    <f7-preloader :size="20"></f7-preloader>
                    <i>{{ $t("loading-website") }}</i>
                  </f7-col>
                </f7-row>
                <f7-row>
                  <f7-col width="50">
                    <div class="icon-progress" v-show="logoLoading"></div>
                  </f7-col>
                  <f7-col width="50">
                    <div class="website-progress" v-show="websiteLoading"></div>
                  </f7-col>
                </f7-row>
              </f7-block>
            </div>

            <div>
              <f7-block-title>
                {{ $t("search-person-photo") }}
              </f7-block-title>
              <f7-list class="person-search" no-hairlines>
                <f7-list-input
                  floating-label
                  outline
                  :label="$t('person-name-company')"
                  type="search"
                  :value="personQuery"
                  placeholder="ex. elon musk tesla"
                  :disabled="personLoading"
                  autocomplete="off"
                  @change="findPerson(1, $event.target.value, $event.target)"
                >
                  <f7-button
                    class="stickerButton"
                    fill
                    icon="icon-search"
                    ref="personsearch"
                    slot="content"
                    @click="findPerson(1, $event.target.value)"
                  ></f7-button>
                </f7-list-input>
              </f7-list>
              <f7-popover class="popover-person">
                <div class="person-list" v-if="!personLoading">
                  <personlist
                    v-for="(person, index) in persons"
                    :key="index"
                    v-bind:url="person.url"
                    v-bind:title="person.title"
                    v-on:addPerson="addPerson"
                  />
                </div>
                <div class="person-loading" v-if="personLoading">
                  <f7-preloader :size="32"></f7-preloader>
                  <i>{{ $t("searching") }}</i>
                </div>
                <div
                  class="person-loading"
                  v-if="!personLoading && Object.keys(persons).length == 0"
                >
                  <i>{{ $t("no-images-found") }}</i>
                </div>
                <f7-block
                  class="load-more-link"
                  v-if="
                    Object.keys(persons).length == 10 &&
                    !personLoading &&
                    personPage < 10
                  "
                >
                  <f7-link @click="findPerson(personPage + 1)">{{
                    $t("load-more")
                  }}</f7-link>
                </f7-block>
              </f7-popover>
            </div>
          </f7-col>

          <f7-col width="35" large="33" v-if="wizardStep == 4">
            <f7-navbar class="sub-nav">
              <f7-block-title> {{ $t("add-to-scene") }} </f7-block-title>
              <f7-subnavbar>
                <f7-segmented raised>
                  <f7-button tab-link="#buttons-tab" tab-link-active>{{
                    $t("buttons-tab")
                  }}</f7-button>
                  <f7-button tab-link="#text-tab">
                    {{ $t("text-tab") }}
                  </f7-button>
                  <f7-button tab-link="#stickers-tab">{{
                    $t("stickers-tab")
                  }}</f7-button>
                </f7-segmented>
              </f7-subnavbar>
            </f7-navbar>
            <f7-tabs>
              <f7-tab id="buttons-tab" tab-active class="page-content">
                <f7-list class="play-list" no-hairlines no-hairlines-between>
                  <ul>
                    <buttonlist
                      v-for="(title, index) in playButtons"
                      :key="index"
                      v-once
                      v-bind:title="title"
                      v-bind:index="index"
                      v-on:setPlayButton="setPlayButton"
                    />
                  </ul>
                </f7-list>
              </f7-tab>

              <f7-tab id="text-tab" class="page-content">
                <f7-list no-hairlines no-hairlines-between v-if="titleLoaded">
                  <f7-list-input
                    floating-label
                    outline
                    :label="$t('title-text')"
                    type="textarea"
                    :resizable="true"
                    :value="textTitle.text"
                    :placeholder="$t('short-catchy-title')"
                    @input="textTitle.text = $event.target.value"
                    clear-button
                  >
                  </f7-list-input>
                  <li>
                    <div class="list simple-list">
                      <div
                        class="item-content item-input inline-label item-input-with-value"
                      >
                        <div class="item-inner">
                          <div
                            class="item-title item-label"
                            v-text="$t('font-family')"
                          ></div>
                          <div
                            class="item-input-wrap input-dropdown input-popover"
                            @click="pickFontFamily"
                            v-text="textTitle.fontFamily"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <f7-popover class="popover-font">
                    <f7-list
                      class="font-list"
                      no-hairlines
                      no-hairlines-between
                    >
                      <ul>
                        <fontlist
                          v-for="font in fontList"
                          :key="font"
                          v-bind:font="font"
                          v-bind:selected="textTitle.fontFamily"
                          v-on:setFontFamily="setFontFamily"
                        />
                      </ul>
                    </f7-list>
                  </f7-popover>

                  <f7-list-input
                    :label="$t('font-style')"
                    inline-label
                    type="select"
                    @change="setFontStyle"
                    :value="textTitle.fontStyle"
                    :placeholder="$t('please-choose')"
                  >
                    <option value="">Normal</option>
                    <option value="bold">Bold</option>
                    <option value="italic">Italic</option>
                  </f7-list-input>
                  <f7-list-input
                    :label="$t('text-align')"
                    inline-label
                    type="select"
                    @change="setTextAlign"
                    :value="textTitle.align"
                    :placeholder="$t('please-choose')"
                  >
                    <option value="left">Left</option>
                    <option value="center">Center</option>
                    <option value="right">Right</option>
                  </f7-list-input>
                  <f7-list-input
                    type="colorpicker"
                    class="colorpicker"
                    inline-label
                    :label="$t('font-color')"
                    :placeholder="$t('color')"
                    readonly
                    :value="{ hex: textTitle.fill }"
                    :color-picker-params="{
                      modules: ['sb-spectrum', 'hue-slider', 'hex'],
                      hexLabel: true,
                      hexValueEditable: true,
                      toolbarCloseText: ' &#x2715; &nbsp;',
                      toolbarPopover: true,
                    }"
                    @colorpicker:change="textTitle.fill = $event.hex"
                  >
                    <f7-icon
                      icon="icon-text"
                      :style="
                        'color:' +
                        textTitle.fill +
                        '; background-color:' +
                        textTitle.stroke +
                        ';'
                      "
                      tooltip="Text color preview"
                      slot="inner-end"
                    ></f7-icon>
                    <f7-link
                      icon="icon-swap"
                      icon-size="20"
                      icon-color="black"
                      @click="invertTextColor"
                      tooltip="Invert"
                      style="margin-right: 0.6em; margin-bottom: -1.8em"
                      slot="inner"
                    ></f7-link>
                  </f7-list-input>
                  <f7-list-input
                    type="colorpicker"
                    class="colorpicker"
                    inline-label
                    :label="$t('outline-color')"
                    placeholder="Color"
                    readonly
                    :value="{ hex: textTitle.stroke }"
                    :color-picker-params="{
                      modules: ['sb-spectrum', 'hue-slider', 'hex'],
                      hexLabel: true,
                      hexValueEditable: true,
                      toolbarCloseText: ' &#x2715; &nbsp;',
                      toolbarPopover: true,
                    }"
                    @colorpicker:change="textTitle.stroke = $event.hex"
                  >
                    <f7-icon
                      icon="icon-outline"
                      :style="
                        'color:' +
                        textTitle.fill +
                        '; background-color:' +
                        textTitle.stroke +
                        ';'
                      "
                      tooltip="Text outline preview"
                      slot="inner-end"
                    ></f7-icon>
                  </f7-list-input>
                  <f7-list-input
                    inline-label
                    :label="$t('font-size')"
                    :input="false"
                  >
                    <f7-range
                      slot="input"
                      :value="textTitle.fontSize"
                      :min="50"
                      :max="200"
                      :step="2"
                      @range:change="textTitle.fontSize = $event"
                    ></f7-range>
                  </f7-list-input>
                  <f7-list-input
                    inline-label
                    :label="$t('outline-size')"
                    :input="false"
                  >
                    <f7-range
                      slot="input"
                      :value="textTitle.strokeWidth"
                      :min="0"
                      :max="4"
                      :step="0.1"
                      @range:change="textTitle.strokeWidth = $event"
                    ></f7-range>
                  </f7-list-input>
                </f7-list>
              </f7-tab>

              <f7-tab id="stickers-tab" class="page-content">
                <f7-list no-hairlines no-hairlines-between>
                  <f7-list-input
                    floating-label
                    outline
                    :label="$t('search-image')"
                    type="search"
                    @change="findStickers(1, $event.target.value)"
                    @input:empty="findStickers(1, $event.target.value)"
                    placeholder="Things like book, dollar, hat, cat"
                  >
                    <f7-button
                      class="stickerButton"
                      fill
                      icon="icon-search"
                      slot="content"
                    ></f7-button>
                  </f7-list-input>
                </f7-list>
                <f7-list
                  v-if="!stickerLoading && stickers"
                  no-hairlines
                  no-hairlines-between
                  class="sticker-list"
                >
                  <ul>
                    <stickerlist
                      v-for="(sticker, index) in stickers"
                      :key="index"
                      v-bind:id="sticker.id"
                      v-bind:url="
                        stickerEndpoint + '/sticker?thumb=' + sticker.id
                      "
                      v-bind:title="sticker.title"
                      v-on:addSticker="addSticker"
                    />
                  </ul>
                </f7-list>
                <f7-block class="sticker-list loading" v-if="stickerLoading">
                  <f7-preloader :size="32"></f7-preloader>
                  <i>{{ $t("searching") }}</i>
                </f7-block>
                <f7-block-header
                  v-if="
                    !stickerLoading &&
                    Object.keys(stickers).length == 0 &&
                    !stickerQuery
                  "
                >
                  {{ $t("stickers-our-favorites") }}
                </f7-block-header>
                <f7-list
                  v-if="
                    !stickerLoading &&
                    Object.keys(stickers).length == 0 &&
                    !stickerQuery
                  "
                  no-hairlines
                  no-hairlines-between
                  class="sticker-list"
                >
                  <ul>
                    <stickerlist
                      v-for="(sticker, index) in ownStickers"
                      :key="index"
                      v-bind:id="index"
                      v-bind:url="'static/images/sticker/' + index + '.png'"
                      v-bind:title="sticker"
                      v-on:addSticker="addOwnSticker"
                    />
                  </ul>
                </f7-list>
                <f7-block
                  class="load-more-link"
                  v-if="Object.keys(stickers).length == 15 && !stickerLoading"
                >
                  <f7-link @click="findStickers(stickerPage + 1)">{{
                    $t("load-more")
                  }}</f7-link>
                </f7-block>
                <div
                  class="load-more-link"
                  v-if="
                    !stickerLoading &&
                    Object.keys(stickers).length == 0 &&
                    stickerQuery
                  "
                >
                  <i>{{ $t("no-images-found") }}</i>
                </div>
              </f7-tab>
            </f7-tabs>
          </f7-col>

          <f7-col width="35" large="33" v-if="wizardStep == 5">
            <f7-block-title large>{{ $t("congratulations") }}</f7-block-title>
            <f7-block-title v-text="$t('ready-for-sharing')"> </f7-block-title>
            <f7-block>{{ $t("save-enter-name") }} </f7-block>
            <f7-block v-if="session && !session.email">
              <p v-text="$t('save-enter-email')"></p>
            </f7-block>
            <f7-list no-hairlines no-hairlines-between>
              <f7-list-input
                floating-label
                outline
                :label="$t('project-name')"
                type="text"
                placeholder=""
                :value="currentProject.title"
                @change="currentProject.title = $event.target.value"
                clear-button
              >
                <creditchip
                  eventName="saved-first-project"
                  :events="events"
                  aligned="right"
                  slot="content"
                  v-if="completed && !completed.includes('saved-first-project')"
                ></creditchip>
              </f7-list-input>
              <f7-list-input
                floating-label
                outline
                :label="$t('email')"
                type="email"
                :placeholder="$t('enter-email-register')"
                v-if="session && !session.email"
                @change="emailForSignIn = $event.target.value"
              >
                <creditchip
                  eventName="saved-email"
                  :events="events"
                  aligned="right"
                  slot="content"
                  @click="emailSignIn"
                  v-if="completed && !completed.includes('saved-email')"
                ></creditchip>
              </f7-list-input>
              <f7-list-item class="right-align-list-button">
                <f7-button
                  fill
                  large
                  icon="icon-save"
                  :class="'saveButton' + (projectSaved ? ' saved' : '')"
                  @click="saveProject()"
                  v-text="
                    projectSaved ? $t('project-saved') : $t('save-project')
                  "
                ></f7-button>
              </f7-list-item>
            </f7-list>

            <div v-if="session && !session.email">
              <f7-block class="register-external">
                Secure your data by creating a free profile using</f7-block
              >
              <!-- <f7-list class="register-email" no-hairlines no-hairlines-between>
                <f7-list-input
                  floating-label
                  outline
                  :label="$t('email')"
                  type="email"
                  :placeholder="$t('enter-email-register')"
                  v-if="session && !session.email"
                  @change="emailForSignIn = $event.target.value"
                >
                  <f7-button
                      class="stickerButton"
                      fill
                      icon="icon-check"
                      slot="content"
                    ></f7-button>
                    <creditchip
                      eventName="saved-email"
                      :events="events"
                      aligned="right"
                      slot="content"
                      @click="emailSignIn"
                      v-if="completed && !completed.includes('saved-email')"
                    ></creditchip>  
                </f7-list-input>   
              </f7-list>-->
              <f7-row
                class="register-buttons"
                v-if="completed && !completed.includes('saved-email')"
              >
                <f7-col width="50">
                  <f7-button @click="googleSignIn">
                    <img src="static/images/google.svg" />
                    <span>Google</span>
                  </f7-button>
                </f7-col>
                <f7-col width="50">
                  <f7-button @click="facebookSignIn">
                    <img src="static/images/facebook.svg" />
                    <span>Facebook</span>
                  </f7-button>
                </f7-col>
              </f7-row>
            </div>

            <div v-if="session && session.email && false">
              <f7-block-title> {{ $t("share-thumbify") }} </f7-block-title>
              <f7-list no-hairlines no-hairlines-between>
                <f7-list-input
                  floating-label
                  outline
                  label="Your unique sharing link"
                  type="text"
                  readonly
                  :value="userShareLink"
                >
                </f7-list-input>
              </f7-list>
              <f7-block>
                <p v-text="$t('share-description')"></p>
              </f7-block>
            </div>
          </f7-col>
          <f7-col v-show="wizardStep > 1" width="65" large="67">
            <v-stage
              ref="stage"
              :config="canvas.settings"
              @mousedown="handleStageMouseDown"
              @touchstart="handleStageMouseDown"
            >
              <v-layer v-if="canvas.background">
                <v-image ref="background" :config="canvas.background">
                </v-image>
              </v-layer>
              <v-layer>
                <v-text
                  ref="texttitle"
                  :config="textTitle"
                  @dragend="handleDragText"
                />
              </v-layer>
              <v-layer
                v-for="image in images"
                :key="image.id"
                @dragend="handleDragImage"
              >
                <v-image :config="image" />
              </v-layer>
              <v-layer>
                <v-image v-if="playImage" ref="play" :config="playImage">
                </v-image>
                <v-image
                  v-if="showWatermark"
                  ref="watermark"
                  :config="watermarkImage"
                ></v-image>
                <v-transformer
                  ref="transformer"
                  @transformend="handleTransformEnd"
                />
              </v-layer>
            </v-stage>

            <f7-list
              class="action-list"
              no-hairlines-between
              v-if="selectedImageId"
            >
              <f7-list-item>
                <f7-button icon="icon-skip" @click="cancelSelected">
                  {{ $t("deselect-image") }}
                </f7-button>
              </f7-list-item>
              <f7-list-item>
                <f7-button icon="icon-up" @click="forwardSelected">
                  {{ $t("forward-image") }}
                </f7-button>
              </f7-list-item>
              <f7-list-item>
                <f7-button icon="icon-trash" @click="deleteSelected">
                  {{ $t("delete-image") }}
                </f7-button>
              </f7-list-item>
            </f7-list>
            <f7-list
              class="action-list"
              no-hairlines-between
              v-if="!selectedImageId && wizardStep != 5"
            >
              <small>
                <f7-icon icon="icon-help"></f7-icon>
                <i>{{ $t("click-to-edit") }}</i>
              </small>
            </f7-list>

            <div v-if="wizardStep == 5 && !selectedImageId">
              <f7-list
                class="share-thumb-list"
                no-hairlines
                no-hairlines-between
              >
                <f7-list-item>
                  <f7-button icon="icon-picture" @click="copyClipboard()">
                    {{ $t("copy-clipboard") }}
                  </f7-button>
                </f7-list-item>
                <f7-list-item>
                  <f7-button icon="icon-mail" @click="copyEmail()">
                    {{ $t("copy-email") }}
                  </f7-button>
                </f7-list-item>
                <f7-list-item>
                  <f7-button icon="icon-download" @click="downloadThumbnail()">
                    {{ $t("download-project") }}
                  </f7-button>
                </f7-list-item>
                <!-- <f7-list-item>
                  <f7-button icon="lni-google" @click="emailThumbnail()">
                    Email
                  </f7-button>
                </f7-list-item> -->
                <f7-list-item
                  v-if="
                    currentProject.watermark || currentProject.watermark == null
                  "
                >
                  <f7-button
                    icon="icon-skip"
                    class="removeButton"
                    @click="removeWatermark()"
                  >
                    {{ $t("remove-watermark") }}
                  </f7-button>
                </f7-list-item>
              </f7-list>
            </div>
          </f7-col>
        </f7-row>
        <f7-row
          v-if="
            !isMobile &&
            !showBGRemover &&
            wizardStep == 0 &&
            (Object.keys(projectList).length == 0 || showStart)
          "
        >
          <!-- <f7-col width="60">
            <f7-block class="explainer-video" v-if="wizardStep == 0">
              <f7-link @click="playExplainer()">
                <video v-show="!showExplainer" autoplay loop muted>
                  <source src="static/video/preview.mp4?3" type="video/mp4" />
                </video>
                <video
                  id="explainer-video"
                  preload="none"
                  v-show="showExplainer"
                >
                  <source src="static/video/explainer.mp4?3" type="video/mp4" />
                </video>
              </f7-link>
              <creditchip
                eventName="watched-explainer"
                aligned="right"
                :events="events"
                v-if="completed && !completed.includes('watched-explainer')"
              ></creditchip>
            </f7-block>
          </f7-col> -->
          <!-- <f7-col width="40" class="start-message">
            <f7-block>
              <h1>{{ $t("start-title") }}</h1>
              <p class="start-description" v-html="$t('start-description')"></p>
              <f7-block>
                <f7-button
                  fill
                  large
                  class="start-button"
                  @click="createProject()"
                >
                  {{ $t("get-started") }}
                  <f7-icon icon="icon-right"></f7-icon>
                </f7-button>
                <f7-link class="quick-start-link" @click="quickStart">{{
                  $t("quick-start-guide")
                }}</f7-link>
              </f7-block>
            </f7-block>
          </f7-col> -->
          <f7-col width="100" class="start-message">
            <f7-block>
              <h1>{{ $t("start-title") }}</h1>
            </f7-block>
          </f7-col>
          <f7-col width="5"></f7-col>
          <f7-col width="30" class="home-block">
            <h2>{{ $t("home-title-1") }}</h2>
            <p>{{ $t("home-description-1") }}</p>
            <f7-link @click="createProject()">
              <img src="static/images/examples/bill.jpg" width="100%" />
            </f7-link>
            <!-- <f7-button large @click="createProject()">
              {{ $t("home-button-1") }}
            </f7-button> -->
          </f7-col>
          <f7-col width="30" class="home-block">
            <h2>{{ $t("home-title-2") }}</h2>
            <p>{{ $t("home-description-2") }}</p>
            <f7-link @click="createProject()">
              <img src="static/images/examples/elon.jpg" width="100%" />
            </f7-link>
            <!-- <f7-button large @click="createProject()">
              {{ $t("home-button-2") }}
            </f7-button> -->
          </f7-col>
          <f7-col width="30" class="home-block">
            <h2>{{ $t("home-title-3") }}</h2>
            <p>{{ $t("home-description-3") }}</p>
            <f7-link @click="createProject()">
              <img src="static/images/examples/steven.jpg" width="100%" />
            </f7-link>
            <!-- <f7-button large @click="createProject()">
              {{ $t("home-button-3") }}
            </f7-button> -->
          </f7-col>
          <f7-col width="5"></f7-col>
        </f7-row>

        <f7-row v-if="isMobile">
          <f7-col>
            <f7-block class="mobile-start">
              <h2>{{ $t("mobile-start-title") }}</h2>
              <p v-html="$t('mobile-start-description')"></p>
            </f7-block>
            <f7-block class="explainer-video">
              <f7-link @click="playExplainer()">
                <video v-show="!showExplainer" autoplay loop muted>
                  <source src="static/video/preview.mp4" type="video/mp4" />
                </video>
                <video
                  id="explainer-video"
                  preload="none"
                  v-show="showExplainer"
                >
                  <source src="static/video/explainer.mp4?2" type="video/mp4" />
                </video>
              </f7-link>
            </f7-block>
          </f7-col>
        </f7-row>

        <f7-row
          v-if="
            !isMobile &&
            !showBGRemover &&
            wizardStep == 0 &&
            Object.keys(projectList).length > 0 &&
            !showStart
          "
        >
          <f7-col width="100">
            <f7-block>
              <f7-block-title class="list-title">
                {{ $t("saved-thumbnails-title") }}
              </f7-block-title>
              <div class="project-list">
                <projectlist
                  v-for="(project, index) in projectList"
                  :key="index"
                  v-bind:project="project"
                  v-on:loadProject="loadProject"
                  v-on:downloadProject="downloadProject"
                  v-on:quickEditProject="quickEditProject"
                  v-on:deleteProject="deleteProject"
                />
              </div>
            </f7-block>
          </f7-col>
        </f7-row>

        <f7-row v-if="!isMobile && !showBGRemover">
          <f7-col
            width="100"
            v-if="
              (wizardStep == 0 && Object.keys(projectList).length == 0) ||
              (Object.keys(projectList).length > 0 && showStart)
            "
          >
            <f7-block
              :class="
                'logo-list' +
                (Object.keys(projectList).length == 0 ? ' padding' : '')
              "
            >
              <f7-block-title>Works with</f7-block-title>
              <f7-row>
                <f7-col width="15" xlarge="10"
                  ><f7-link href="https://zoom.us" target="_system" external
                    ><img
                      src="static/images/logo/zoom.svg"
                      alt="Zoom - Video Conferencing" /></f7-link
                ></f7-col>
                <!-- <f7-col width="20" xlarge="10"
                  ><f7-link href="https://vimeo.com" target="_system" external
                    ><img src="static/images/logo/vimeo.svg" /></f7-link
                ></f7-col> -->
                <f7-col width="15" xlarge="10"
                  ><f7-link href="https://vaam.io" target="_system" external
                    ><img
                      src="static/images/logo/vaam.svg"
                      alt="Vaam.io - Video as a message" /></f7-link
                ></f7-col>
                <f7-col width="15" xlarge="10"
                  ><f7-link
                    href="https://www.drift.com"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/drift.svg"
                      alt="Drift - Revenue Acceleration Platform" /></f7-link
                ></f7-col>
                <f7-col width="20" xlarge="10"
                  ><f7-link href="https://www.canned.me" target="_system" external
                    ><img
                      src="static/images/logo/canned.svg"
                      alt="Canned.me - Bringing personal video to where you live" /></f7-link
                ></f7-col>
                <f7-col width="15" xlarge="10"
                  ><f7-link
                    href="https://www.loom.com"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/loom.svg"
                      alt="Loom: Video Messaging for Work" /></f7-link
                ></f7-col>
                <f7-col width="20" xlarge="10"
                  ><f7-link
                    href="https://www.getaccept.com/?ref=thumbify"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/getaccept.svg"
                      alt="GetAccept - Best all-in-one Sales Enablement platform for B2B sales" /></f7-link
                ></f7-col>
                <f7-col width="15" xlarge="10" style="padding-left: 1.5em"
                  ><f7-link href="https://www.gong.io" target="_system" external
                    ><img
                      src="static/images/logo/gong.svg"
                      alt="Gong.io - The #1 platform for remote sales teams" /></f7-link
                ></f7-col>
                <f7-col width="20" xlarge="10"
                  ><f7-link
                    href="https://www.chorus.ai/"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/chorus.svg"
                      alt="Chorus.ai | Conversation Intelligence for Sales Teams" /></f7-link
                ></f7-col>
                <f7-col width="15" xlarge="10"
                  ><f7-link
                    href="https://www.youtube.com"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/youtube.svg"
                      alt="YouTube" /></f7-link
                ></f7-col>
                <f7-col
                  width="20"
                  xlarge="10"
                  style="margin-left: -1em; margin-right: 1em"
                  ><f7-link
                    href="https://www.getcloudapp.com"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/cloudapp.svg"
                      alt="CloudApp Screen Recording Software" /></f7-link
                ></f7-col>
                <f7-col width="20" xlarge="10"
                  ><f7-link
                    href="https://www.vidyard.com"
                    target="_system"
                    external
                    ><img
                      src="static/images/logo/vidyard.svg"
                      alt="Vidyard - Online Video Hosting for Business" /></f7-link
                ></f7-col>
              </f7-row>
            </f7-block>
            <f7-block
              class="credit-explanation"
              v-if="this.completed.length < 4"
            >
              <creditchip
                style=""
                eventName="collect-credits"
                :events="{ 'collect-credits': '' }"
                aligned="text"
              ></creditchip>
              <p v-text="$t('credit-explanation')"></p>
            </f7-block>
          </f7-col>

          <f7-col width="50" v-if="wizardStep > 0 && wizardStep < 5">
            <f7-button
              class="nextButton"
              fill
              large
              @click="nextWizardStep('+')"
            >
              {{ $t("next") }}
            </f7-button>
          </f7-col>
          <f7-col
            width="50"
            v-if="
              !isMobile &&
              ((wizardStep == 0 && Object.keys(projectList).length > 0) ||
                wizardStep == 5)
            "
          >
            <f7-button
              class="createButton"
              fill
              large
              icon="icon-plus"
              @click="createProject()"
            >
              {{ $t("create-new") }}
            </f7-button>
          </f7-col>

          <div class="userButtons">
            <f7-row v-if="!isMobile">
              <f7-col v-if="session && session.isAnonymous">
                <f7-button
                  popup-open=".popup-login"
                  v-text="$t('login-register')"
                ></f7-button>
              </f7-col>
              <f7-col v-if="session && !session.isAnonymous">
                <f7-button @click="signOut()" v-text="$t('logout')"></f7-button>
              </f7-col>

              <f7-col
                v-if="
                  wizardStep == 0 &&
                  Object.keys(projectList).length > 0 &&
                  !showStart
                "
              >
                <f7-button @click="showStart = true">
                  {{ $t("show-start") }}
                </f7-button>
              </f7-col>
              <f7-col
                v-if="
                  wizardStep == 0 &&
                  Object.keys(projectList).length > 0 &&
                  showStart
                "
              >
                <f7-button @click="showStart = false">
                  {{ $t("show-project-library") }}
                </f7-button>
              </f7-col>
              <f7-col
                v-if="wizardStep > 0 && Object.keys(projectList).length > 0"
              >
                <f7-button @click="clearProject(0)">
                  {{ $t("show-project-library") }}
                </f7-button>
              </f7-col>
            </f7-row>
          </div>

          <f7-button
            icon="icon-bug"
            class="debugButton"
            popover-open=".popover-debug"
            v-if="this.apiProjectId != 'thumbify-me'"
          ></f7-button>
        </f7-row>

        <f7-row v-if="!isMobile && showBGRemover" class="remove-bg">
          <f7-col width="50">
            <f7-block class="padding">
              <h1>Remove Image Background</h1>
              <h2>
                100% Automatic and <span style="font-weight: 800">Free</span>
              </h2>
              <p>
                Try our Free Background Remover which uses deep learning and
                advanced algorithms to remove even complex backgrounds from your
                image in just a few seconds.
              </p>
              <p>
                Using our automatic background remover, you can generate a
                transparent background for free and use this image in designs,
                presentations, sales documents and
                <f7-link href="/">video thumbnails</f7-link>. Drag the slider in
                the sample below to see what our background removal process
                looks like:
              </p>
            </f7-block>
            <div class="remove-compare">
              <VueCompareImage
                leftImage="./static/images/before.jpg?2"
                leftLabel="Before"
                rightImage="./static/images/after.jpg?2"
                rightLabel="After"
                :sliderPositionPercentage="0.5"
              />
            </div>
          </f7-col>
          <f7-col width="50">
            <div
              class="upload-box"
              @click="launchFilePicker('free-bg-remove')"
              v-if="!downloadFreeBackground"
            >
              <div
                class="image-upload"
                v-if="!determinateUploadLoading"
                style="background-image: url(./static/images/checker.png)"
              >
                <img :src="previewUploadImage" v-if="previewUploadImage" />
                <img
                  src="static/images/upload.png"
                  class="button-image"
                  v-if="!previewUploadImage"
                />
              </div>
              <div
                class="image-scanning"
                v-if="determinateUploadLoading"
                style="background-image: url(./static/images/scan.gif)"
              >
                <img :src="previewUploadImage" v-if="previewUploadImage" />
              </div>

              <f7-button
                large
                fill
                icon="icon-camera"
                popup-close
                :disabled="determinateUploadLoading"
                >Upload Image</f7-button
              >
            </div>
            <div
              class="download-box"
              v-if="downloadFreeBackground"
              @click="downloadFreeBackgroundImage()"
            >
              <div
                class="image-upload"
                style="background-image: url(./static/images/checker.png)"
              >
                <img :src="downloadFreeBackground" />
              </div>
              <f7-button large fill icon="icon-download" popup-close
                >Download Image (-2 credits)</f7-button
              >
              <f7-button
                large
                icon="icon-camera"
                popup-close
                v-if="previewUploadImage"
                @click="launchFilePicker('free-bg-remove')"
                :disabled="determinateUploadLoading"
                >Upload another Image</f7-button
              >
            </div>

            <f7-button large class="create-new-button" href="/">
              Create a Free Video thumbnail with Thumbify
              <f7-icon icon="icon-right"></f7-icon>
            </f7-button>
          </f7-col>
        </f7-row>

        <input
          type="file"
          ref="file"
          style="display: none"
          accept="image/*"
          @change="handleFileUpload"
        />
      </f7-card-content>
    </f7-card>

    <webcampopup v-on:uploadWebcam="uploadWebcam" />

    <f7-popup
      class="popup-uploadpreview"
      swipe-to-close="to-bottom"
      swipe-handler=".swipe-handler"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-title
            v-text="
              Object.keys(uploadPreviewImages).length
                ? $t('upload-done')
                : $t('upload-processing')
            "
          ></f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close :tooltip="$t('close')">
              <f7-icon icon="icon-close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <div
            class="upload-preview-description"
            v-show="!Object.keys(uploadPreviewImages).length"
            v-html="$t('upload-processing-description')"
          ></div>
          <div
            class="upload-preview-description"
            v-show="Object.keys(uploadPreviewImages).length"
            v-html="$t('upload-select-version')"
          ></div>
          <div class="upload-preview-list">
            <div
              v-show="
                !Object.keys(uploadPreviewImages).length && previewUploadImage
              "
              class="image-scanning"
              style="background-image: url(./static/images/scan.gif)"
            >
              <img :src="previewUploadImage" v-if="previewUploadImage" />
            </div>
            <div
              v-show="
                !Object.keys(uploadPreviewImages).length && previewWebcamImage
              "
              class="image-scanning"
              v-if="determinateWebcamLoading"
              style="background-image: url(./static/images/scan.gif)"
            >
              <img :src="previewWebcamImage" v-if="previewWebcamImage" />
            </div>
            <div
              v-for="(image, index) in uploadPreviewImages"
              :key="index"
              :class="
                'upload-preview-card' + (canvas.background ? '' : ' checked')
              "
              :style="
                `background-image:url(` +
                (canvas.background
                  ? canvas.background.image.src
                  : './static/images/checker.png') +
                `)`
              "
              @click="selectUploadVersion(image)"
            >
              <div class="upload-preview-title" v-text="image.title"></div>
              <div class="upload-preview-image"><img :src="image.src" /></div>
            </div>
          </div>
        </f7-block>
        <div
          style="text-align: center; padding-top: 1em"
          v-if="uploadType == 'webcam' && !determinateWebcamLoading"
        >
          <f7-button icon="icon-camera" popup-close @click="launchWebcam()">
            {{ $t("retake-photo") }}
          </f7-button>
        </div>
        <div
          style="text-align: center; padding-top: 1em"
          v-if="uploadType == 'photo' && !determinateUploadLoading"
        >
          <f7-button
            icon="icon-camera"
            popup-close
            @click="launchFilePicker('photo')"
          >
            {{ $t("reupload-photo") }}
          </f7-button>
        </div>
      </f7-page>
    </f7-popup>

    <librarypopup
      v-on:selectLibraryImage="selectLibraryImage"
      v-on:deleteFile="deleteFile"
    />
    <bglibrarypopup
      v-on:selectBackgroundImage="selectBackgroundImage"
      v-on:deleteFile="deleteFile"
    />

    <copypopup v-on:copyEmail="copyEmail" />
    <copyemailpopup />

    <f7-popup
      class="popup-quickedit"
      swipe-to-close="to-bottom"
      swipe-handler=".swipe-handler"
      @popup:close="closeQuickEdit"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-title> {{ $t("quick-edit") }} </f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close :tooltip="$t('close')">
              <f7-icon icon="icon-close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block-title>
          {{ $t("modify-link-title") }}
        </f7-block-title>
        <div style="padding: 0.1em 1.2em">
          <p v-html="$t('modify-link-description')"></p>
        </div>

        <f7-list no-hairlines>
          <f7-list-input
            floating-label
            outline
            :label="$t('video-url')"
            type="url"
            tabindex="2"
            :value="currentProject.videoUrl"
            :disabled="videoLoading"
            @input="currentProject.videoUrl = $event.target.value"
            clear-button
          >
          </f7-list-input>
        </f7-list>

        <div style="text-align: center; padding-top: 1em">
          <!-- <f7-button icon="icon-picture" @click="copyQuickClipboard()">
            {{ $t("copy-clipboard") }}
          </f7-button> -->
          <f7-button icon="icon-mail" @click="copyQuickEmail()">
            {{ $t("copy-email") }}
          </f7-button>
        </div>
      </f7-page>
    </f7-popup>

    <f7-popup
      class="popup-outofcredits"
      swipe-to-close="to-bottom"
      swipe-handler=".swipe-handler"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-right>
            <f7-link popup-close :tooltip="$t('close')">
              <f7-icon icon="icon-close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block style="padding: 0 3em">
          <h1>
            {{ $t("credit-get-title") }}
          </h1>
          <p>{{ $t("credit-get-description") }}</p>
        </f7-block>
        <div class="partner-box">
          <creditchip
            eventName="visit-partner-website"
            :amount="50"
            aligned="right"
          ></creditchip>
          <f7-link
            href="https://www.getaccept.com/?ref=thumbify"
            target="_system"
            external
            @click="addCustomCredit('visit-partner-website', 50)"
          >
            <img src="static/images/logo/getaccept.svg" />
            <h3>{{ $t("credit-visit-partner-website") }}</h3>
            <p>{{ $t("credit-visit-partner-description") }}</p>
          </f7-link>
        </div>

        <div class="partner-box">
          <creditchip
            eventName="trial-partner-tool"
            :amount="500"
            aligned="right"
          ></creditchip>
          <f7-link @click="partnerTrial()">
            <img
              src="https://www.getaccept.com/hs-fs/hubfs/Video%20getaccept%20.gif?width=600&name=Video%20getaccept%20.gif"
            />
            <h3>{{ $t("credit-trial-partner-tool") }}</h3>
            <p>{{ $t("credit-trial-partner-description") }}</p>
          </f7-link>
        </div>
      </f7-page>
    </f7-popup>

    <loginpopup
      v-on:signInWithEmail="signInWithEmail"
      v-on:saveSignInUser="saveSignInUser"
      v-on:googleSignIn="googleSignIn"
      v-on:facebookSignIn="facebookSignIn"
    />

    <div class="footer-credit">
      Made with <f7-icon icon="icon-heart"></f7-icon> by
      <f7-link
        href="https://www.getaccept.com/?ref=thumbify"
        target="_system"
        external
        ><img
          src="static/images/ga-logo.svg"
          alt="GetAccept - Best all-in-one Sales Enablement platform for B2B sales"
      /></f7-link>
      AND
      <f7-link href="https://salesresearchlabs.com" target="_system" external
        ><img src="static/images/labs-logo.svg" alt="Sales Research Labs"
      /></f7-link>
    </div>

    <f7-card
      class="guide-card"
      v-if="
        isMobile ||
        (wizardStep == 0 && (Object.keys(projectList).length == 0 || showStart))
      "
    >
      <f7-card-content>
        <f7-row>
          <f7-col width="100" medium="50">
            <h2>Creating Your Next Video Thumbnail</h2>
            <p>
              We’ve made making video thumbnails a breeze. Follow the steps
              below to create an eye catching video thumbnail:
            </p>
            <ol>
              <li>
                Paste the link to the video or link you are wanting to share. We
                will automatically find and locate the default thumbnail if it
                is available
              </li>
              <li>
                Experiment with changing the video thumbnail backgrounds - try
                some of our fun templates or upload one of your own
              </li>
              <li>
                Personalize your video thumbnail by uploading images, using your
                webcam or selecting previous uploads from your library. We also
                can auto-detect the logo and website of any company you enter
                into the search bar
              </li>
              <li>
                Finalize your video thumbnail with fun add-ons like a range of
                buttons, text and stickers. It’s all about making that thumbnail
                pop
              </li>
              <li>
                Copy the video thumbnail to your clipboard, download a high
                resolution version or save your video thumbnail to reuse next
                time
              </li>
            </ol>

            <h2>
              Virtual Background Creator for Zoom, GoToMeeting, Microsoft Teams
              and Google Hangouts
            </h2>
            <p>
              Thumbify.me can also be used to create fun and interesting virtual
              backgrounds on popular online meeting/team collaboration software
              products. Simply follow the same steps above and click the
              download button once you complete your design to upload your
              background into your choice of online meeting software.
            </p>

            <h2>Video Thumbnail Generation for Marketing</h2>
            <p>
              Marketers can take advantage of Thumbify.me to create catchy
              thumbnails for a range of video advertisements and corporate
              videos shared on social media like LinkedIn, Facebook and Twitter
              or to assist sales teams to receive more content engagement when
              materials are delivered via email
            </p>
          </f7-col>
          <f7-col width="100" medium="50">
            <h2>Video Thumbnail Generator for Sales</h2>
            <p>
              Sales professionals have been using video to stand out for
              sometime - sending snippets via email or LinkedIn. The problem is
              that video for sales has now become an extremely popular technique
              with most videos looking the same or hidden as hyperlink which are
              often missed in the inbox. This means that quality content is
              being unwatched despite the effort to make it personalize. Avoid
              this by harnessing Thumbify.me to make quality, personal video
              thumbnails for sales that stand out in the inbox and ensure that
              your videos are clicked not just some of the time, but all the
              time!
            </p>

            <h2>Video Messaging Software</h2>
            <p>
              The rise of video messaging software has been rapid, with
              providers like Vidyard, Drift Video, Loom and Cloudapp allowing
              companies to communicate faster both internally and with
              customers. Thumbify.me works with a vast number of video messaging
              software platforms allowing you to transform standard links or
              thumbnails from these services into a video thumbnail that is
              going to be clicked three times more. Should you be using a
              provider which is not supported please use the feedback tab on the
              right of this screen to pass on your suggestions
            </p>

            <h2>Sales Video Software</h2>
            <p>
              Thumbify.me is proud to offer integrations into a number of
              different sales video softwares used by professional sales teams.
              Sales representatives in the age of remote sales are not more
              often than not sharing call recordings using Zoom’s native call
              recording functionality and tools like Chorus.ai and Gong.io. In
              addition tools like Vidyard, Drift Video, Loom, Cloudapp and
              GetAccept are being used more frequently to allow sales reps to
              combine video with screen recordings, meeting excerpts, documents
              and contracts to increase engagement and build deals to close.
              Thumbify.me is a video thumbnail generator that works in
              conjunction with these innovative sales video software tools.
            </p>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col
            ><f7-link href="/how-to-make-a-video-thumbnail"
              >How to Make a Video Thumbnail</f7-link
            ></f7-col
          >
          <f7-col
            ><f7-link href="/how-to-make-a-youtube-video-thumbnail"
              >How to Make a Youtube Video Thumbnail</f7-link
            ></f7-col
          >
          <f7-col
            ><f7-link href="/how-to-make-a-vimeo-video-thumbnail"
              >How to Make a Vimeo Video Thumbnail</f7-link
            ></f7-col
          >
        </f7-row>
      </f7-card-content>
    </f7-card>
    <div
      class="guide-bottom"
      v-if="
        !isMobile &&
        wizardStep == 0 &&
        (Object.keys(projectList).length == 0 || showStart)
      "
    ></div>
  </f7-page>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

import moment from 'moment'

import * as copy from 'copy-to-clipboard';
import FontFaceObserver from 'fontfaceobserver';

const emptyTextTitle = {
  x: 100,
  y: 40,
  text: '',
  wrap: 'word',
  width: 500,
  fontFamily: 'Anton',
  fontSize: 96,
  fontStyle: 'bold',
  fill: '#333333',
  align: 'left',
  stroke: '#ffffff',
  strokeWidth: .1,
  draggable: true
}
const emptyProject = {
  id: null,
  name: null,
  url: null,
  userId: null,
  companyName: null,
  companyDomain: null,
  videoUrl: null,
  playImageId: null,
  bgId: null,
  bgSource: null,
  textTitle: null,
  watermark: true
}

import { firebaseConfig } from '../js/config';

export default {
  data() {
    return {
      isMobile: false,
      currentProject: emptyProject,
      projectList: {},
      projectThumbs: {},
      wizardStep: 0,
      showLinkOptions: true,
      progressDialog: null,
      sharingCode: null,
      projectSaved: false,
      determinateUploadLoading: false,
      determinateWebcamLoading: false,
      determinateBackgroundLoading: false,
      videoLoading: false,
      logoLoading: false,
      personLoading: false,
      stickerLoading: false,
      websiteLoading: false,
      previewUploadImage: null,
      downloadFreeBackground: null,
      previewWebcamImage: null,
      uploadBackgroundImage: null,
      uploadType: null,
      webcamImage: null,
      uploadPreviewImages: [],
      addOutline: false,
      backgroundsBasic: {
        12: 'Black wood',
        1: 'Office',
        2: 'Living room',
        3: 'Cafe',
        4: 'Home office',
        8: 'Website',
        9: 'Kitchen'
      },
      backgroundsFun: {
        14: 'Parks and Recreation',
        13: 'Prison cell',
        7: 'Ski lift',
        5: 'First Class',
        6: 'Sales Wars',
        16: 'Carpool bubbles',
        15: 'Super bowl',
        10: 'Distracted boyfriend',
      },
      playButtons: {
        0: 'No button',
        1: 'White round',
        2: 'Blue round',
        3: 'Dark square',
        4: 'Red round',
        5: 'Black round',
        6: 'Red square',
        7: 'Orange round',
        8: 'Pointer',
        9: 'Cursor'
      },
      backgroundBlur: 0,
      uploadProgress: 0,
      websiteImage: null,
      videoImage: null,
      logoImage: null,
      companyLogoImage: null,
      playImage: null,
      showWatermark: false,
      showStart: false,
      showExplainer: false,
      showBGRemover: false,
      textTitle: emptyTextTitle,
      availableFonts: ['Bangers', 'Allerta Stencil', 'Ultra', 'Anton', 'Bebas Neue', 'Lilita One', 'Permanent Marker', 'Sedgwick Ave Display', 'Cabin sketch', 'Rubik', 'Oswald', 'Arvo'],
      titleLoaded: false,
      apiProjectId: firebaseConfig.projectId,
      backgroundEndpoint: document.location.port == '80801' ? 'http://localhost:5000' : 'https://' + firebaseConfig.authDomain + '/api',
      screenshotEndpoint: document.location.port == '80801' ? 'http://localhost:5001' : 'https://' + firebaseConfig.authDomain + '/api',
      videoEndpoint: document.location.port == '80801' ? 'http://localhost:5002' : 'https://' + firebaseConfig.authDomain + '/api',
      logoEndpoint: document.location.port == '80801' ? 'http://localhost:5003' : 'https://' + firebaseConfig.authDomain + '/api',
      stickerEndpoint: document.location.port == '80801' ? 'http://localhost:5004' : 'https://' + firebaseConfig.authDomain + '/api',
      personEndpoint: document.location.port == '80801' ? 'http://localhost:5005' : 'https://' + firebaseConfig.authDomain + '/api',
      images: [],
      persons: [],
      stickers: [],
      ownStickers: {
        1: 'Ski goggles',
        2: 'NFL helmet',
        3: 'Suit',
        4: 'Sunglasses',
        5: 'Headset',
        6: 'Pink hat',
        7: 'Beard',
        8: 'Mask',
        9: 'Police',
        10: 'Hat',
        11: 'Cowboy',
        12: 'Click here',
        13: 'Click me',
        14: 'Book now',
      },
      deleteImages: [],
      personQuery: null,
      personPage: 1,
      stickerQuery: null,
      stickerPage: 1,
      selectedImageId: null,
      canvas: {
        settings: {
          width: 640,
          height: 360,
          pixelRatio: 2
        },
        background: null
      },
      watermarkImage: null,

      user: null,
      credit: 0,
      completed: [],
      events: {
        'watched-explainer': 10,
        'created-first-project': 10,
        'saved-first-project': 10,
        'saved-email': 50,
        'refer-a-friend': 10,
        'create-five-projects': 5,
        'used-webcam': 5,
        'add-customized-text': 5,
        'add-company-logo': 5
      },

      session: null,
      emailForSignIn: null
    }
  },
  computed: {
    // device() {
    //   return this.devices.find(n => n.deviceId === this.deviceId)
    // },
    fontList() {
      return this.availableFonts.sort()
    },

    userShareLink() {
      if (this.user) {
        let host = document.location.port == '8080' ? 'http://localhost:8080' : 'https://' + document.location.hostname
        return host + '/?i=' + this.user.shareLink
      }
    }
  },
  watch: {
    // uploadProgress() {
    //   if (this.progressDialog && typeof this.progressDialog.setProgress === 'function' && this.uploadProgress) {
    //     this.progressDialog.setProgress(this.uploadProgress)
    //   }
    // },
    // camera(id) {
    //   console.log('watch camera', id)
    //   // this.deviceId = id
    // },
    wizardStep() {
      if (this.wizardStep == 3) {
        setTimeout(this.initCompanySearch, 400)
      }
    }
  },
  methods: {
    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      let img = this.images.find(
        (r) => r.name === this.selectedImageId
      )
      // update the state
      img.x = e.target.x()
      img.y = e.target.y()
      img.rotation = e.target.rotation()
      img.scaleX = e.target.scaleX()
      img.scaleY = e.target.scaleY()
    },
    handleDragImage(e) {
      const selecedId = e.target.name()
      let img = this.images.find((r) => r.name === selecedId)
      img.x = e.target.attrs.x
      img.y = e.target.attrs.y
    },
    handleDragText(e) {
      if (e && e.target.attrs) {
        this.textTitle.x = e.target.attrs.x
        this.textTitle.y = e.target.attrs.y
      }
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedImageId = ''
        this.updateTransformer()
        return
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer'
      if (clickedOnTransformer) {
        return
      }

      // find clicked img by its id
      const selecedId = e.target.name()
      const img = this.images.find((r) => r.name === selecedId)
      if (img) {
        this.selectedImageId = selecedId
      } else {
        this.selectedImageId = ''
      }
      this.updateTransformer()
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode()
      const stage = transformerNode.getStage()
      const { selectedImageId } = this

      const selectedNode = stage.findOne('.' + selectedImageId)
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode])
      } else {
        // remove transformer
        transformerNode.nodes([])
      }
      transformerNode.getLayer().batchDraw()
    },
    deleteSelected() {
      const stage = this.$refs.stage.getStage()
      if (this.selectedImageId) {
        const selectedNode = stage.findOne('.' + this.selectedImageId)
        // console.log('delete', this.selectedImageId, selectedNode)
        // selectedNode.getParent().destroy()
        // return
        this.images = this.images.filter((r) => {
          return r.name !== this.selectedImageId
        })
        if (selectedNode.attrs.filename == this.selectedImageId + '.png') {
          this.deleteImages.push(selectedNode.attrs.filename)
          // console.log('deletedImages', this.deleteImages)
        }

        // Clear selection
        this.selectedImageId = ''
        this.updateTransformer()

      }
    },
    cancelSelected() {
      if (this.selectedImageId) {
        this.selectedImageId = ''
        this.updateTransformer()
      }
    },
    forwardSelected() {
      if (this.selectedImageId) {
        const stage = this.$refs.stage.getStage()
        const selectedNode = stage.findOne('.' + this.selectedImageId)
        if (selectedNode) {
          let index
          for (let i in this.images) {
            if (this.images[i].name == this.selectedImageId) {
              index = i
            }
          }
          this.images.push(this.images.splice(index, 1)[0])
          this.selectedImageId = ''
          this.updateTransformer()
          stage.draw()
        }
      }
    },
    backwardSelected() {
      if (this.selectedImageId) {
        const stage = this.$refs.stage.getStage()
        const selectedNode = stage.findOne('.' + this.selectedImageId)
        if (selectedNode) {
          let index
          for (let i in this.images) {
            if (this.images[i].name == this.selectedImageId) {
              index = i
            }
          }
          this.images.unshift(this.images.splice(index, 1)[0])
          this.selectedImageId = ''
          this.updateTransformer()
          stage.draw()
        }
      }
    },
    downloadFreeBackgroundImage() {
      this.$f7.preloader.show()
      if (this.credit <= 0) {
        this.launchOOC()
        return
      }

      this.toDataURL(this.downloadFreeBackground, imgData => {
        let link = document.createElement('a')
        let filename = 'thumbify-me.png'
        if (this.$refs.file.files && this.$refs.file.files[0].name) {
          let fileTitle = this.$refs.file.files[0].name
          let filepart = fileTitle.match(/([^:\\/]*?)(?:\.([^ :\\/.]*))?$/)
          filename = filepart[1] + '.png'
        }
        link.href = imgData
        link.download = filename
        link.className = 'link external'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.$f7.preloader.hide()
        this.$f7.toast.create({
          icon: '<i class="icon icon-point-down"></i>',
          text: 'The image has been downloaded',
          position: 'bottom',
          cssClass: 'download',
          closeTimeout: 5000
        }).open()

        if (this.credit <= 0) {
          this.launchOOC()
        }
        setTimeout(() => {
          this.downloadFreeBackground = null
          this.previewUploadImage = null
        }, 5000)
      })
      this.credit = this.credit - 2
      this.creditNotification(-2, 'removing-background')
      this.saveUser()
    },
    downloadThumbnail() {
      const stage = this.$refs.stage.getStage()
      this.$f7.preloader.show()
      if (this.selectedImageId) {
        this.selectedImageId = ''
        this.updateTransformer()
      }

      let dataURL = stage.toDataURL({ pixelRatio: 2 })
      this.getDownloadThumbnail(dataURL)
    },
    getDownloadThumbnail(url) {
      let link = document.createElement('a')
      let title = this.currentProject.title ? this.currentProject.title : this.currentProject.id
      let filename = title.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.png'
      link.href = url
      link.download = filename
      link.className = 'link external'
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      this.$f7.preloader.hide()
      this.$f7.toast.create({
        icon: '<i class="icon icon-point-down"></i>',
        text: this.$t('thumbnail-downloaded'),
        position: 'bottom',
        cssClass: 'download',
        closeTimeout: 5000
      }).open()
    },
    emailThumbnail() {

    },
    updateProject(params) {
      var promise = new Promise((resolve, reject) => {
        // console.log('updateProject', params)
        if (this.currentProject.id) {
          firebase.database().ref('projects').child(firebase.auth().currentUser.uid).child(this.currentProject.id).update(params).then((data) => {
            resolve()
          })
        }
        else {
          reject()
        }
      })
      return promise
    },
    nameProject() {
      const app = this.$f7
      var promise = new Promise((resolve, reject) => {
        if (!this.currentProject.title) {
          console.log('name project')
          let defaultProjectName = this.currentProject.companyName ? this.currentProject.companyName : this.currentProject.companyDomain
          app.dialog.prompt('What should we call the thumbnail project?', 'Enter Project Name', (val) => {
            //Save
            this.currentProject.title = val
            resolve()

          }, () => {
            //Cancel

          }, defaultProjectName)
        }
        else {
          resolve()
        }
      })
      return promise
    },
    saveProject() {
      const stage = this.$refs.stage.getStage()
      if (this.selectedImageId) {
        this.selectedImageId = ''
        this.updateTransformer()
      }

      if (this.session.isAnonymous && !this.emailForSignIn) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: 'Please enter your email or use 3rd party verification to claim your free account & save your thumbnail',
          position: 'center',
          closeTimeout: 5000
        }).open()
        return
      }

      // Name the project
      this.nameProject().then(() => {
        this.$f7.preloader.show()

        // Collect stage information
        const stage = this.$refs.stage.getStage()

        // Save background
        // let bgNode = stage.findOne('.bg')
        // if (bgNode && bgNode.attrs.image.src.substring(0,5) != 'https'){
        //   this.storeProjectFile('bg.png', bgNode.toDataURL())
        // }

        // Save images
        this.images.forEach((img) => {
          this.storeProjectFile(img.name + '.png', img.image.currentSrc)
        })

        // Save website
        if (this.websiteImage) {
          this.storeProjectFile('website.png', this.websiteImage)
        }

        // Save video
        if (this.videoImage) {
          this.storeProjectFile('video.png', this.videoImage)
        }

        // Save background
        if (this.uploadBackgroundImage) {
          this.storeProjectFile('background.png', this.uploadBackgroundImage)
        }

        // Save stage data
        this.currentProject.images = this.images
        this.currentProject.textTitle = this.textTitle
        this.currentProject.backgroundBlur = this.backgroundBlur
        this.currentProject.updatedAt = firebase.database.ServerValue.TIMESTAMP

        // Remove deleted images
        if (this.deleteImages) {
          for (let fileName of this.deleteImages) {
            //Remove files from storage
            this.removeProjectFile(fileName)
          }
        }

        // Store thumb
        let thumbUrl = stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.9, pixelRatio: 1.5 })
        this.storeProjectFile('thumb.jpg', thumbUrl).then((downloadUrl) => {
          this.currentProject.thumbUrl = downloadUrl

          // console.log('save project')
          // Save project
          this.updateProject(this.currentProject).then(() => {
            this.$f7.preloader.hide()
            this.$f7.toast.create({
              icon: '<i class="icon-save"></i>',
              text: 'Project has been saved',
              position: 'center',
              closeTimeout: 1000
            }).open()
            this.projectSaved = true

            // Store hq
            // let fullUrl = stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.9, pixelRatio: 1.5 })
            // this.storeProjectFile('full.jpg', fullUrl).then(() => {
            // })

            this.addCredits('saved-first-project')
          })

        })

        // Sign up with email
        if (this.emailForSignIn && this.session.isAnonymous) {
          this.signInWithEmail(this.emailForSignIn)
        }

        firebase.analytics().logEvent('save_project')
        if (analytics) {
          analytics.track("save_project", {
            name: this.currentProject.title
          })
        }
      })
    },
    copyClipboard() {
      const stage = this.$refs.stage.getStage()
      this.$f7.preloader.show()
      if (this.selectedImageId) {
        this.selectedImageId = ''
        this.updateTransformer()
      }
      let dataURL = stage.toDataURL({ pixelRatio: 2 })
      this.copyOnlyImage(dataURL)
    },
    copyEmail() {
      this.$f7.preloader.show()
      setTimeout(() => {
        const stage = this.$refs.stage.getStage()
        if (this.selectedImageId) {
          this.selectedImageId = ''
          this.updateTransformer()
        }

        let fullUrl = stage.toDataURL({ mimeType: 'image/jpeg', quality: 0.9, pixelRatio: 1.5 })
        let imageId = new Date().getTime()
        let imagePath = firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/' + imageId + '.jpg'
        let downloadURL = firebaseConfig.publicHost + imagePath
        this.copyWithImage(downloadURL)

        this.storePublicVersion(imagePath, fullUrl).then(downloadUrl => {

        })
      }, 100)
      firebase.analytics().logEvent('copy_clipboard')
      if (analytics) {
        analytics.track("copy_clipboard", {
          name: this.currentProject.title
        })
      }

      // Future popup for > 3 times visit and copy/downloading a thumbnail
      // Thank you for coming back and making Thumbify part of your favourite apps. To ensure you don’t lose your progress and your thumbnail designs - please enter your email or authenticate your account. Plus, we will give you 50 extra thumbnail credits
    },
    copyQuickClipboard() {
      if (this.currentProject.publicUrl) {
        let url = this.currentProject.publicUrl
        this.copyOnlyImage(url)
      }
      else {
        let imageId = new Date().getTime()
        let imagePath = firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/' + imageId + '.jpg'
        let url = firebaseConfig.publicHost + imagePath
        this.copyOnlyImage(url)
      }
    },
    copyQuickEmail() {
      this.$f7.preloader.show()
      if (this.currentProject.publicUrl) {
        let url = this.currentProject.publicUrl
        this.copyWithImage(url)
      }
      else {
        let imageId = new Date().getTime()
        let imagePath = firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/' + imageId + '.jpg'
        let url = firebaseConfig.publicHost + imagePath
        this.copyWithImage(url)

        this.loadProjectFile('thumb.jpg').then(imgData => {
          this.storePublicVersion(imagePath, imgData).then(downloadUrl => {
          })
        })
        this.updateProject({ publicUrl: downloadURL })
      }
    },
    copyOnlyImage(dataURL) {
      if (dataURL.split(',').length == 1) {
        this.toDataURL(dataURL, imgData => {
          this.copyOnlyImage(imgData)
        })
        return
      }
      if (typeof navigator.clipboard == 'undefined') {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: 'Your browser doesn\'t seem to support copying of image. Please try Copy for email or download the image.',
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        this.$f7.preloader.hide()
        return
      }
      var arr = dataURL.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      let blob = new Blob([u8arr], { type: mime });
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]).then(e => {
        this.$f7.preloader.hide()
        this.$f7.popup.close('.popup-quickedit')
        this.$f7.popup.open('.popup-copy')
      })
        .catch(e => {
          console.log(e)
          this.$f7.preloader.hide()
        })
      firebase.analytics().logEvent('copy_clipboard')
      if (analytics) {
        analytics.track("copy_clipboard", {
          name: this.currentProject.title
        })
      }
    },
    copyWithImage(downloadUrl) {
      let img = new Image()
      let title = this.currentProject.title ? this.currentProject.title : 'thumbify.me'
      let filename = title.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '.jpg'
      img.src = downloadUrl
      img.style = 'display:block;width:440px;height:248px;border:0;text-decoration:none;outline:none;'
      img.width = 440
      img.height = 248
      img.alt = 'Video thumbnail by thumbify.me'
      img.title = 'Video thumbnail by thumbify.me'
      img.download = filename
      let link
      if (this.currentProject.videoUrl) {
        link = document.createElement('a')
        link.href = this.currentProject.videoUrl
        link.download = filename
        link.title = 'Video thumbnail by thumbify.me'
        link.appendChild(img)
      }
      let div = document.createElement('div')
      div.style = 'display:block;width:440px;height:248px;border:0;text-decoration:none;outline:none;'
      if (link) {
        div.appendChild(link)
      }
      else {
        div.appendChild(img)
      }

      this.sharingCode = div.outerHTML

      let success = copy('<br>' + div.outerHTML + '<br>', {
        debug: true,
        format: 'text/html',
        message: 'Press #{key} to copy',
        onCopy: () => {
          this.$f7.preloader.hide()
        }
      })

      if (success) {
        this.$f7.popup.close('.popup-quickedit')
        this.$f7.popup.open('.popup-copyemail')
      }
      else {
        this.$f7.preloader.hide()
      }
    },
    setBlur(val) {
      if (typeof (val) != 'undefined') {
        this.backgroundBlur = val
      }
      if (this.$refs.background) {
        let bg = this.$refs.background.getNode()
        bg.cache()
        bg.blurRadius(this.backgroundBlur)
        bg.getLayer().batchDraw()
      }
    },

    pickFontFamily(e) {
      this.$f7.popover.open('.popover-font', e.target)
    },
    setFontFamily(fontFamily) {
      let font = new FontFaceObserver(fontFamily)
      font.load().then(() => {
        this.textTitle.fontFamily = fontFamily
        this.addCredits('add-customized-text')
      }, (err) => {
        console.log('Font is not available')
      })
      this.$f7.popover.close('.popover-font')
    },
    setFontStyle(e) {
      let fontStyle = e.target.value
      this.textTitle.fontStyle = fontStyle
    },
    setTextAlign(e) {
      let textAlign = e.target.value
      this.textTitle.text += ' '
      this.textTitle.align = textAlign
      this.textTitle.text = this.textTitle.text.substr(0, this.textTitle.text.length - 1)
    },
    invertTextColor() {
      let tmp = this.textTitle.fill
      this.textTitle.fill = this.textTitle.stroke
      this.textTitle.stroke = tmp
    },

    setPlayButton(id) {
      const stage = this.$refs.stage.getStage()
      if (id == 0) {
        //No button
        this.currentProject.playImageId = null
      }
      else if (id) {
        this.currentProject.playImageId = id
      }
      if (this.currentProject.playImageId) {
        let playImage = new Image()
        playImage.src = './static/images/play/' + this.currentProject.playImageId + '.png'
        playImage.onload = () => {
          let xOffset = (stage.width() / 2) - 64
          let yOffset = (stage.height() / 2) - 64
          this.playImage = {
            image: playImage,
            x: xOffset,
            y: yOffset,
            width: 128,
            height: 128
          }
          this.updateProject({ playImageId: this.currentProject.playImageId })
        }
      }
      else {
        this.playImage = null
      }
    },
    setBackground(source, id) {
      const stage = this.$refs.stage.getStage()
      if (source == 'video') {
        this.setCanvasBackground(source, this.videoImage)
      }
      else if (source == 'website') {
        this.setCanvasBackground(source, this.websiteImage)
      }
      else if (source == 'upload') {
        this.setCanvasBackground(source, this.uploadBackgroundImage)
      }
      else if (source == 'bg' && id > 0) {
        this.setCanvasBackground(source, './static/images/bg/bg-' + id + '.jpg', id)
      }
      else {
        this.canvas.background = null
      }
    },
    setCanvasBackground(source, bgSrc, id) {
      const stage = this.$refs.stage.getStage()
      let bg = this.$refs.background
      if (bg) {
        bg.getNode().clearCache()
      }

      this.currentProject.bgSource = source ? source : null
      this.currentProject.bgId = id ? id : null
      let bgImage = new Image()
      bgImage.src = bgSrc
      bgImage.onload = () => {
        let yOffset = 0
        let xOffset = 0
        if (bgImage.width == 480 && bgImage.height == 360) {
          yOffset = -60
        }
        this.canvas.background = {
          name: 'bg',
          image: bgImage,
          x: xOffset,
          y: yOffset,
          width: stage.width(),
          height: stage.width() * (bgImage.height / bgImage.width),
          filters: [Konva.Filters.Blur]
        }
        this.updateProject({
          bgSource: this.currentProject.bgSource,
          bgId: this.currentProject.bgId
        })
        if (this.backgroundBlur) {
          setTimeout(this.setBlur, 200)
        }
      }
    },
    launchFilePicker(type) {
      this.uploadType = type
      this.downloadFreeBackground = null
      this.previewUploadImage = null
      this.determinateUploadLoading = false
      this.$refs.file.value = null
      this.$refs.file.click()
    },
    launchWebcam() {
      this.uploadType = 'webcam'
      this.previewUploadImage = null
      this.webcamImage = null
      this.determinateWebcamLoading = false
      this.$f7.popup.open('.popup-webcam')
    },
    launchLibrary() {
      this.$f7.popup.open('.popup-library')
    },
    launchBGLibrary() {
      this.$f7.popup.open('.popup-bglibrary')
    },
    launchUploadPreview() {
      if (this.determinateWebcamLoading || this.determinateUploadLoading) {
        this.$f7.popup.open('.popup-uploadpreview')
      }
    },
    launchOOC() {
      this.$f7.popup.open('.popup-outofcredits')
    },
    partnerTrial() {
      let app = this.$f7
      if (!this.emailForSignIn && !this.user.email) {
        // this.emailForSignIn = window.prompt('Please provide your email for confirmation')
        app.dialog.prompt('Please provide your email for signing up', 'Free trial', (email) => {
          this.emailForSignIn = email
          this.partnerTrial()
        }, () => {
          return
        })
        return
      }
      if (!this.emailForSignIn && !this.user.email) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: 'Invalid or empty email entered. Please try again',
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        return
      }
      let postData = {
        'user-email': this.emailForSignIn ? this.emailForSignIn : this.user.email,
        'b_8ffc72b0378c08003ff1fae1f_37f364ae36': '',
        'user-registration-source': 'thumbify',
        'user-registration-campaign': '',
        'entity-name': ''
      }
      let postOptions = {
        url: 'https://app.getaccept.com/profile?callback=?',
        data: postData,
        crossDomain: true,
        dataType: 'json',
      }
      app.request.promise(postOptions).then((res) => {
        if (res.data) {
          console.log(res.data)
          if (res.data.error) {
            this.$f7.toast.create({
              icon: '<i class="icon-warning"></i>',
              text: res.data.error,
              position: 'center',
              cssClass: 'warning',
              closeTimeout: 6000,
              closeButton: true,
            }).open()
            app.dialog.prompt('Please provide another email for signing up for the free trial account', res.data.error, (email) => {
              this.emailForSignIn = email
              this.partnerTrial()
            }, () => {
              return
            })
            return
          }
          else if (res.data.registration_url) {
            this.addCustomCredit('trial-partner-tool', 500)
            window.open(res.data.registration_url)
          }
        }
      })
    },
    selectUploadVersion(image) {
      if (image.type == 'free-bg-remove') {
        this.downloadFreeBackground = image.src
      }
      else {
        this.addCanvasLayer(image.src)
      }
      this.storeLibraryImage(image.type, image.src)
      this.$f7.popup.close('.popup-uploadpreview')
    },
    uploadWebcam(imgData, blob) {
      this.previewWebcamImage = imgData
      this.determinateWebcamLoading = true
      this.uploadFile(blob, 'webcam')
      this.$f7.popup.close('.popup-webcam')
      setTimeout(() => {
        this.addCredits('used-webcam')
      }, 2000)

    },
    // showUploadProgress() {
    //   const app = this.$f7
    //   if (this.determinateUploadLoading) {
    //     return
    //   }
    //   this.determinateUploadLoading = true
    //   let progressBarEl
    //   this.progressDialog = app.dialog.progress(this.$t('events.uploadingimage'), 0)
    // },
    handleFileUpload() {
      this.webcamImage = null
      this.previewUploadImage = null
      this.uploadBackgroundImage = null
      this.uploadProgress = 0
      if (this.$refs.file.files.length > 0 && this.$refs.file.files[0].size > 7000000) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: this.$t('maximum-filesize').replace('XX', '7 Mb'),
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        return
      }
      if (this.uploadType == 'photo' || this.uploadType == 'webcam' || this.uploadType == 'free-bg-remove') {
        this.uploadFile(this.$refs.file.files[0], this.uploadType)
      }
      // Load preview file
      let reader = new FileReader()
      reader.onload = () => {
        if (this.uploadType == 'photo') {
          this.previewUploadImage = reader.result
        }
        else if (this.uploadType == 'free-bg-remove') {
          this.previewUploadImage = reader.result
        }
        else if (this.uploadType == 'background') {
          this.uploadBackgroundImage = reader.result
          this.setBackground('upload')
          this.storeBackgroundImage('background', reader.result)
        }
      }
      reader.readAsDataURL(this.$refs.file.files[0])
    },
    storeProjectFile(fileName, fileData) {
      var promise = new Promise((resolve, reject) => {
        if (!fileData || fileData.substring(0, 5) == 'https') {
          resolve(fileData)
          return
        }

        let storageRef = firebase.storage().ref('projects/' + firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/' + fileName)
        let uploadTask = storageRef.putString(fileData, 'data_url')
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + progress + ' done')
        }, (error) => {
          console.log(error)
          reject(error)
        }, () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL)
          })
        })
      })
      return promise
    },

    storePublicVersion(imagePath, fileData) {
      var promise = new Promise((resolve, reject) => {
        let storageRef = firebase.app().storage(firebaseConfig.publicBucket).ref(imagePath)
        let uploadTask = storageRef.putString(fileData, 'data_url')
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + progress + ' done')
        }, (error) => {
          console.log(error)
          reject(error)
        }, () => {
          let downloadURL = firebaseConfig.publicHost + imagePath
          // Store to project
          this.updateProject({ publicUrl: downloadURL })
          resolve(downloadURL)
        })
      })
      return promise
    },

    loadProjectFile(fileName) {
      var promise = new Promise((resolve, reject) => {
        if (!this.currentProject.id) {
          reject('No project id')
        }
        else {
          let storageRef = firebase.storage().ref('projects/' + firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/' + fileName).getDownloadURL()
            .then((url) => {
              this.toDataURL(url, imgData => {
                resolve(imgData)
              })
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
      return promise
    },
    removeProjectFile(fileName) {
      let storageRef = firebase.storage().ref('projects/' + firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/' + fileName).delete().then().catch()
    },
    addCanvasLayer(layerData, param) {
      let canvasImage = new Image()
      canvasImage.src = layerData
      canvasImage.onload = () => {
        let imageWidth = 400
        if (imageWidth * (canvasImage.height / canvasImage.width) > 300) {
          imageWidth = 300 * (canvasImage.width / canvasImage.height)
        }
        let imageId = new Date().getTime()
        let imageData = Object.assign({
          name: 'image_' + imageId,
          image: canvasImage,
          x: 20,
          y: this.canvas.settings.height - (imageWidth * (canvasImage.height / canvasImage.width)),
          width: imageWidth,
          height: imageWidth * (canvasImage.height / canvasImage.width),
          draggable: true
        }, param)
        this.images.push(imageData)
        // this.storeProjectFile(imageData.name + '.png', imageData)
      }

    },
    uploadFile(file, type) {
      let app = this.$f7
      let postData = new FormData()
      this.uploadPreviewImages = []
      if (!file || (file && file.size < 2000)) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: type == 'webcam' ? this.$t('camera-error') : this.$t('upload-error'),
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        this.webcamImage = null
        this.determinateWebcamLoading = false
        return
      }
      postData.append('file', file)
      if (type == 'webcam') {
        this.determinateWebcamLoading = true
      }
      else if (type == 'photo' || type == 'free-bg-remove') {
        this.determinateUploadLoading = true
      }
      setTimeout(this.launchUploadPreview, 500)
      postData.append('outline', this.addOutline)
      app.request.promise.post(this.backgroundEndpoint + '/background',
        postData,
        'json',
      ).then((res) => {
        if (res.data && res.data.img) {
          let imageData = 'data:image/png;base64,' + res.data.img
          this.uploadPreviewImages.push({
            type: type,
            title: this.$t('upload-original'),
            src: imageData
          })

          if (res.data.outline) {
            let outlineData = 'data:image/png;base64,' + res.data.outline
            this.uploadPreviewImages.push({
              type: type,
              title: this.$t('upload-outlined'),
              src: outlineData
            })
          }

          if (type == 'webcam') {
            this.webcamImage = imageData
            this.determinateWebcamLoading = false
          }
          else if (type == 'photo' || type == 'free-bg-remove') {
            this.previewUploadImage = imageData
            this.determinateUploadLoading = false
          }

          // this.addCanvasLayer(imageData)
          // this.storeLibraryImage(type, imageData)
        }
        else {
          // Some error
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: res.data.error ? res.data.error : this.$t('background-not-detected'),
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000,
            closeButton: true,
          }).open()
          if (type == 'webcam') {
            this.webcamImage = null
            this.determinateWebcamLoading = false
          }
          else if (type == 'photo' || type == 'free-bg-remove') {
            this.previewUploadImage = null
            this.determinateUploadLoading = false
          }
          this.uploadProgress = 0
        }
      }).catch((err) => {
        console.log(err)
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: this.$t('background-not-detected'),
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        if (type == 'webcam') {
          this.webcamImage = null
          this.determinateWebcamLoading = false
        }
        else if (type == 'photo' || type == 'free-bg-remove') {
          this.previewUploadImage = null
          this.determinateUploadLoading = false
        }
        this.uploadProgress = 0
      })
    },

    storeBackgroundImage(type, fileData) {
      let imageId = new Date().getTime()
      let fileTitle = ''
      let fileExt = 'png'
      if (this.$refs.file.files && this.$refs.file.files[0].name) {
        fileTitle = this.$refs.file.files[0].name
        let filepart = fileTitle.match(/([^:\\/]*?)(?:\.([^ :\\/.]*))?$/)
        fileTitle = filepart[1]
        fileTitle = fileTitle.charAt(0).toUpperCase() + fileTitle.slice(1)
        fileExt = filepart[2]
        if (['png', 'jpg', 'jpeg'].indexOf(fileExt) == -1) {
          fileExt = 'png'
        }
      }

      let fileId = type + '_' + imageId
      let fileName = fileId + '.' + fileExt

      let storageRef = firebase.storage().ref('backgrounds/' + firebase.auth().currentUser.uid + '/' + fileName)
      let uploadTask = storageRef.putString(fileData, 'data_url')
      uploadTask.on('state_changed', (snapshot) => {
      }, (error) => {
        console.log(error)
      }, () => {
        //Done
        uploadTask.snapshot.ref.getDownloadURL().then((thumbUrl) => {
          firebase.database().ref('backgrounds').child(firebase.auth().currentUser.uid).child(fileId).set({
            type: type,
            thumbUrl: thumbUrl,
            fileName: fileName,
            fileTitle: fileTitle,
            createdAt: firebase.database.ServerValue.TIMESTAMP
          }).then((data) => {
          })
        })

      })
    },
    selectBackgroundImage(image) {
      this.$f7.preloader.show()
      this.toDataURL(image.thumbUrl, imgData => {
        this.uploadBackgroundImage = imgData
        this.setBackground('upload')
        this.$f7.preloader.hide()
      })
      this.$f7.popup.close('.popup-bglibrary')
    },

    selectLibraryImage(image) {
      this.$f7.preloader.show()
      this.toDataURL(image.thumbUrl, imgData => {
        this.addCanvasLayer(imgData)
        this.$f7.preloader.hide()
      })
      this.$f7.popup.close('.popup-library')
    },

    storeLibraryImage(type, fileData) {
      let imageId = new Date().getTime()
      let fileId = type + '_' + imageId
      let fileName = fileId + '.png'
      let storageRef = firebase.storage().ref('library/' + firebase.auth().currentUser.uid + '/' + fileName)
      let uploadTask = storageRef.putString(fileData, 'data_url')
      uploadTask.on('state_changed', (snapshot) => {
      }, (error) => {
        console.log(error)
      }, () => {
        //Done
        uploadTask.snapshot.ref.getDownloadURL().then((thumbUrl) => {
          firebase.database().ref('library').child(firebase.auth().currentUser.uid).child(fileId).set({
            type: type,
            thumbUrl: thumbUrl,
            fileName: fileName,
            createdAt: firebase.database.ServerValue.TIMESTAMP
          }).then((data) => {
          })
        })

      })
    },


    validateURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
      return !!pattern.test(str)
    },
    fetchWebsite(url) {
      var promise = new Promise((resolve, reject) => {
        let app = this.$f7
        let postData = {
          url: url
        }
        if (this.websiteLoading) {
          return reject('Already loading')
        }
        this.websiteLoading = true
        app.request.promise.post(this.screenshotEndpoint + '/screenshot',
          postData,
          'json',
        ).then((res) => {
          this.websiteLoading = false
          if (res.data && res.data.img) {
            return resolve(res.data.img)
          }
          this.tooltipInit()
        }).catch((err) => {
          this.websiteLoading = false
          return reject(err)
        })
        this.websiteLoading = true
      })
      return promise
    },
    fetchLogo(url) {
      var promise = new Promise((resolve, reject) => {
        let app = this.$f7
        let postData = {
          url: url
        }
        if (this.logoLoading) {
          return reject('Already loading')
        }
        this.logoLoading = true
        app.request.promise.post(this.logoEndpoint + '/logo',
          postData,
          'json',
        ).then((res) => {
          this.logoLoading = false
          if (res.data && res.data.img) {
            return resolve(res.data.img)
          }
        }).catch((err) => {
          this.logoLoading = false
          return reject(err)
        })
        this.logoLoading = true
      })
      return promise
    },
    addLogo() {
      let logoParams = {
        scaleX: 0.25,
        scaleY: 0.25,
        x: 10,
        y: 280
      }
      this.addCanvasLayer(this.logoImage, logoParams)
    },

    addWebsite() {
      let websiteParams = {
        fill: '#fff',
        shadowEnabled: true,
        shadowBlur: 10,
        shadowOpacity: 0.1,
        shadowColor: '#000',
        width: 400,
        height: 220,
        scaleX: 0.62,
        scaleY: 0.62,
        x: 334,
        y: 128
      }
      this.addCanvasLayer(this.websiteImage, websiteParams)
    },

    fetchSticker(id) {
      var promise = new Promise((resolve, reject) => {
        let app = this.$f7
        let postData = {
          id: id
        }
        app.request.promise.post(this.stickerEndpoint + '/sticker',
          postData,
          'json',
        ).then((res) => {
          if (res.data && res.data.img) {
            return resolve(res.data.img)
          }
        }).catch((err) => {
          return reject(err)
        })
      })
      return promise
    },
    addSticker(id) {
      this.fetchSticker(id).then(imgData => {
        this.addCanvasLayer(imgData)
      })
    },
    addOwnSticker(id) {
      let url = './static/images/sticker/' + id + '.png'
      this.toDataURL(url, imgData => {
        this.addCanvasLayer(imgData)
      })
    },
    findStickers(page, query) {
      this.stickerPage = page
      if (this.stickerLoading) {
        return
      }
      if (typeof query != 'undefined') {
        this.stickerQuery = query
      }
      if (!this.stickerQuery) {
        this.stickers = []
        return
      }
      this.stickerLoading = true
      let postData = {
        query: this.stickerQuery,
        page: this.stickerPage
      }
      let app = this.$f7
      app.request.promise.post(this.stickerEndpoint + '/sticker',
        postData,
        'json',
      ).then((res) => {
        if (res.data && res.data.result) {
          this.stickers = res.data.result
        }
        this.stickerLoading = false
      }).catch((err) => {
        this.stickerLoading = false
      })

    },
    findPerson(page, query) {
      this.personPage = page
      if (this.personLoading) {
        return
      }
      if (typeof query != 'undefined') {
        this.personQuery = query
      }
      if (this.$refs.personsearch) {
        this.$f7.popover.open('.popover-person', this.$refs.personsearch.$el)
      }
      if (!this.personQuery) {
        this.persons = []
        return
      }
      this.personLoading = true
      let postData = {
        query: this.personQuery,
        page: this.personPage
      }
      let app = this.$f7
      app.request.promise.post(this.personEndpoint + '/person',
        postData,
        'json',
      ).then((res) => {
        if (res.data && res.data.result) {
          this.persons = res.data.result
        }
        this.personLoading = false
        this.tooltipInit()
      }).catch((err) => {
        this.personLoading = false
      })

    },
    addPerson(url) {
      this.$f7.preloader.show()
      this.uploadFile(url, 'photo')
      // this.toDataURL(url, imgData => {
      this.previewUploadImage = url
      this.$f7.preloader.hide()
      // })
      this.$f7.popover.close('.popover-person')
    },
    fetchVideo(e) {
      let app = this.$f7
      if (typeof e != 'undefined') {
        this.currentProject.videoUrl = e.target.value
      }
      if (this.videoLoading) {
        return
      }
      if (!this.currentProject.videoUrl || !this.validateURL(this.currentProject.videoUrl)) {
        return
      }
      let postData = {
        url: this.currentProject.videoUrl
      }
      this.videoLoading = true
      this.showProgressbar('.video-progress', 20)
      app.request.promise.post(this.videoEndpoint + '/video',
        postData,
        'json',
      ).then((res) => {
        if (res.data.img) {
          this.videoImage = res.data.img
          if (!this.canvas.background) {
            this.setBackground('video')
          }
          // this.updateProject({ videoUrl: this.currentProject.videoUrl })
          if (res.data.extra) {
            this.addCanvasLayer(res.data.extra.image, res.data.extra.parameters)
          }
        }
        else {
          // Something wrong
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: this.$t('fetch-url-error'),
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000,
            closeButton: true,
          }).open()
        }
        this.videoLoading = false
        this.tooltipInit()
      }).catch((err) => {
        console.log(err)
        if (err.status == 404) {
          err.message = this.$t('video-not-found')
        }
        if (err.message) {
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: err.message,
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000,
            closeButton: true,
          }).open()
        }
        this.videoLoading = false
      })
    },

    createProject() {
      if (!firebase.auth().currentUser) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: 'Couldn\'t find any user session. Please try to refresh the page or try again a little later.',
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        return
      }
      this.$f7.preloader.show()
      this.clearProject(1)
      firebase.database().ref('projects').child(firebase.auth().currentUser.uid).push({
        createdAt: firebase.database.ServerValue.TIMESTAMP
      }).then((data) => {
        this.clearProject(1)
        // console.log('Project created', data.key)
        this.currentProject.id = data.key
        this.currentProject.url = '#!/' + firebase.auth().currentUser.uid + '/' + this.currentProject.id + '/edit'
        // history.pushState({}, null, this.currentProject.url)
        this.$f7.preloader.hide()
        if (!this.user.projectCount) {
          this.user.projectCount = 1
        }
        else {
          this.user.projectCount++
        }
        this.showStart = false
        this.addCredits('created-first-project')

        // Reset ui
        this.$f7.tab.show('#basic-tab')
        this.$f7.tab.show('#buttons-tab')

        if (Object.keys(this.projectList).length == 5) {
          this.addCredits('create-five-projects')
        }

        firebase.analytics().logEvent('create_project')
        if (analytics) {
          analytics.track("create_project")
        }

        // this.$f7router.navigate({
        //   name: 'project',
        //   params: { userId: firebase.auth().currentUser.uid, projectId: data.key }
        // })

        // this.$f7router.navigate(projectUrl {
        //   pushState: true,
        //   reloadAll: false,
        //   reloadCurrent: false,
        //   ignoreCache: true
        // })
      })
    },
    nextWizardStep(step) {
      if (step == '+') {
        this.wizardStep++
      }
      else {
        this.wizardStep = step
      }
      if (this.wizardStep < 1) {
        this.wizardStep = 1
      }
      if (this.wizardStep > 5) {
        this.wizardStep = 5
      }
      if (this.wizardStep == 4) {
        this.titleLoaded = true
      }
      if (this.wizardStep == 5) {
        this.showWatermark = this.currentProject.watermark != null ? this.currentProject.watermark : true
      }
      else {
        this.showWatermark = false
      }
      if (this.selectedImageId) {
        this.selectedImageId = ''
        this.updateTransformer()
      }
    },
    loadUser() {
      firebase.database().ref('users').child(firebase.auth().currentUser.uid).once('value', snapshot => {
        this.user = snapshot.val()
        if (this.user) {
          this.credit = this.user.credit ? this.user.credit : 10
          this.completed = this.user.completed ? this.user.completed : this.completed
          if (this.user.email && analytics) {
            let domaincompany = this.user.email.replace(/.*@/, "").split('.')[0]
            if (domaincompany) {
              domaincompany = domaincompany.charAt(0).toUpperCase() + domaincompany.slice(1)
              analytics.group(domaincompany, {
                name: domaincompany
              })
            }
            analytics.identify(this.user.uid, {
              name: this.user.displayName,
              email: this.user.email,
              company: domaincompany,
              avatar: this.user.photoURL ? this.user.photoURL : null,
              credit: this.user.credit ? this.user.credit : 0,
              projects: this.user.projects ? this.user.projects : 0,
              createdAt: this.user.createdAt
            })
          }
        }
        else {
          //Something wrong or profile not found, signout and reauth
          firebase.auth().signOut().then(() => {
            // Sign-out successful.
            this.user = null
            this.session = null
            this.signIn()
          }).catch((error) => {
            // An error happened.
          })
        }
        this.$f7.preloader.hide()
      })
    },

    listProjects() {
      firebase.database().ref('projects').child(firebase.auth().currentUser.uid).on('value', snapshot => {
        let list = {}
        snapshot.forEach((childSnapshot) => {
          let obj = {
            [childSnapshot.key]: Object.assign(childSnapshot.val(), { id: childSnapshot.key })
          }
          list = Object.assign(obj, list)
          // list[childSnapshot.key] = childSnapshot.val()
          // list[childSnapshot.key].id = childSnapshot.key
        })
        this.projectList = list
      })
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest()
      xhr.onload = function () {
        var reader = new FileReader()
        reader.onloadend = function () {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    },
    loadProjectThumbs() {
      for (let proj in this.projectList) {
        let storageRef = firebase.storage().ref('projects/' + firebase.auth().currentUser.uid + '/' + this.projectList[proj].id + '/thumb.jpg')
        storageRef.getDownloadURL().then((downloadURL) => {
          this.projectThumbs[proj] = downloadURL
        }).catch(error => {
        })
      }
    },
    loadProject(project) {
      this.currentProject = project
      this.projectSaved = false
      this.loadLayers(project)
      this.wizardStep = 1
    },

    quickEditProject(project) {
      this.$f7.popup.open('.popup-quickedit')
      this.currentProject = project
    },
    downloadProject(project) {
      this.$f7.preloader.show()
      this.currentProject = project
      this.loadProjectFile('thumb.jpg').then(imgData => {
        this.getDownloadThumbnail(imgData)
        this.$f7.preloader.hide()
        this.clearProject(0)
      })
    },
    closeQuickEdit() {
      this.clearProject(0)
    },

    async loadLayers(project) {

      // Load project text
      if (project.textTitle) {
        this.textTitle = project.textTitle
      }

      // Load project button
      if (project.playImageId) {
        this.setPlayButton()
      }

      // Load background blur
      if (project.backgroundBlur) {
        this.backgroundBlur = project.backgroundBlur
      }

      // Load project background
      if (project.bgSource) {
        if (project.bgSource == 'video') {
          this.loadProjectFile('video.png').then(imgData => {
            this.videoImage = imgData
            this.setBackground('video')
          })
        }
        else if (project.bgSource == 'website') {
          this.loadProjectFile('website.png').then(imgData => {
            this.websiteImage = imgData
            this.setBackground('website')
          })
        }
        else if (project.bgSource == 'bg') {
          this.setBackground('bg', project.bgId)
        }
        else if (project.bgSource == 'upload') {
          this.loadProjectFile('background.png').then(imgData => {
            this.uploadBackgroundImage = imgData
            this.setBackground('upload')
          })
        }
        else {
          // console.log(project.bgSource)
        }
      }

      // Finally Load project images
      if (project.images) {
        // project.images.forEach(image => {
        for (let image of project.images) {
          //Add layer to canvas
          await this.loadProjectFile(image.name + '.png').then(imgData => {
            let img = new Image()
            img.onload = () => {
              image.image = img
              image.filename = image.name + '.png'
              this.images.push(image)
            }
            img.src = imgData
          }).catch(err => {
            console.log(err)
          })
        }
      }

    },
    deleteFolderContents(path) {
      const ref = firebase.storage().ref(path)
      ref.listAll()
        .then(dir => {
          dir.items.forEach(fileRef => {
            this.deleteFile(ref.fullPath, fileRef.name)
          })
          dir.prefixes.forEach(folderRef => {
            this.deleteFolderContents(folderRef.fullPath)
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteFile(pathToFile, fileName) {
      const ref = firebase.storage().ref(pathToFile)
      const childRef = ref.child(fileName)
      childRef.delete()
    },
    deleteProject(project) {
      const app = this.$f7
      let title = project.title ? ' ' + project.title : ''
      app.dialog.confirm(this.$t('remove-project-confirm'), this.$t('remove-project-title') + title + '?', () => {
        // console.log('Delete ', project.id)

        // Delete storage
        this.deleteFolderContents('projects/' + firebase.auth().currentUser.uid + '/' + this.currentProject.id)

        // Delete database
        let dbRef = firebase.database().ref('projects').child(firebase.auth().currentUser.uid).child(project.id)
        dbRef.remove().then(() => {
        }).catch(error => {
          console.log(error)
        })
      }, () => {
        //Cancel

      })
    },
    clearProject(gotoStep) {
      // Example top-logo click
      this.$set(this, 'currentProject', emptyProject)
      this.currentProject = emptyProject
      this.currentProject.videoUrl = ''
      this.currentProject.playImageId = null
      this.currentProject.companyName = ''
      this.currentProject.companyDomain = ''
      this.projectSaved = false
      this.textTitle = emptyTextTitle
      this.$set(this, 'textTitle', emptyTextTitle)
      this.textTitle.text = null
      this.textTitle.text = ''
      this.images = []
      this.previewUploadImage = null
      this.previewWebcamImage = null
      this.uploadBackgroundImage = null
      this.stickerQuery = ''
      this.stickers = []
      this.backgroundBlur = 0
      this.webcamImage = null
      this.websiteImage = null
      this.videoImage = null
      this.logoImage = null
      this.playImage = null
      this.canvas.background = null
      this.showLinkOptions = true
      this.$forceUpdate()
      this.wizardStep = gotoStep
    },
    initWatermark() {
      let wmImage = new Image()
      wmImage.src = './static/images/watermark.svg'
      wmImage.onload = () => {
        this.watermarkImage = {
          image: wmImage,
          x: 0,
          y: 42,
          width: 640,
          height: 320
        }
      }
    },
    creditNotification(amount, val) {
      let text
      let icon = 'icon-thumbify'
      if (amount > 0) {
        text = amount + ' ' + this.$t('thumb-credits-added') + ' ' + this.$t('events.' + val).toLowerCase()
      }
      else {
        text = amount + ' ' + this.$t('thumb-credits-removed') + ' ' + this.$t('events.' + val).toLowerCase()
        icon = 'icon-thumbify'
      }
      let pop = this.$f7.popover.create({
        content: '<div class="popover notify-credit"><div class="popover-angle"></div><div class="popover-inner"><div class="block padding"><i class="icon ' + icon + '"></i>' + text + '</div></div></div>',
        targetEl: '.user-credit',
        backdrop: false
      })
      pop.open()
      setTimeout(() => {
        pop.close()
      }, 5000)
    },

    removeWatermark() {
      this.credit = this.credit - 2
      this.currentProject.watermark = false
      this.updateProject(this.currentProject).then(() => {
        this.showWatermark = false
        this.saveUser()
      })
      this.creditNotification(-2, 'removing-watermark')
      if (this.credit <= 0) {
        this.launchOOC()
      }
    },

    addCredits(eventName) {
      if (!this.completed || !this.completed.includes(eventName)) {
        let amount = this.events[eventName]

        this.credit += amount
        this.completed.push(eventName)
        this.saveUser()

        this.creditNotification(amount, eventName)
      }
      firebase.analytics().logEvent(eventName.replace(/\-/g, '_'))
      if (analytics) {
        analytics.track(eventName.replace(/\-/g, '_'))
      }
    },

    addCustomCredit(eventName, amount) {
      this.credit += amount
      this.creditNotification(amount, eventName)
    },

    outofCredits() {

    },

    saveUser() {
      //Update user database and profile
      // console.log('saveuser',firebase.auth().currentUser, this.user)
      if (this.credit) {
        this.user.credit = this.credit
      }
      if (this.completed) {
        this.user.completed = this.completed
      }

      if (firebase.auth().currentUser && this.user) {
        firebase.database().ref('users').child(firebase.auth().currentUser.uid).update({
          uid: this.user.uid,
          displayName: this.user.displayName ? this.user.displayName : null,
          email: this.user.email ? this.user.email : null,
          photoURL: this.user.photoURL ? this.user.photoURL : null,
          isAnonymous: this.user.isAnonymous ? this.user.isAnonymous : (this.user.email ? false : true),
          credit: this.user.credit ? this.user.credit : 10,
          completed: this.user.completed ? this.user.completed : [],
          shareLink: this.user.shareLink ? this.user.shareLink : null,
          projects: this.user.projectCount ? this.user.projectCount : 0,
          createdAt: this.user.createdAt ? this.user.createdAt : firebase.database.ServerValue.TIMESTAMP
        }).then(() => {
          // console.log('User db updated')
          firebase.auth().currentUser.updateProfile({
            displayName: this.user.displayName,
            email: this.user.email,
            photoURL: this.user.photoURL
          }).then(() => {
            // console.log('User profile updated')
          }).catch(err => {
            console.log(err)
          })
          this.$f7.preloader.hide()
        })
      }
    },
    saveProfile() {

    },
    initDB() {
      // Initialize Firebase
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
      }
    },
    initRecaptcha() {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('signupButton', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit()
        }
      })
    },
    onSignInSubmit() {

    },
    signIn() {
      this.$f7.preloader.show()
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // console.log('existing user found', user)
          this.session = user
          this.loadUser()
          this.listProjects()
          this.initSignInWithEmailLink()
          this.pageUser()
        }
        else {
          firebase.auth().signInAnonymously().then((data) => {
            // Signed in..
            // console.log('anonymous user created', data.user)
            this.session = data.user
            this.user = data.user
            this.saveUser()

            // Handle invite url
            if (this.inviteLinkId) {
              firebase.database().ref('links').child(this.inviteLinkId).child(firebase.auth().currentUser.uid).once('value', snapshot => {
                if (snapshot.val()) {
                  // Invited by added to db
                  // console.log('Invited by ' + snapshot.val())
                  firebase.database().ref('invited').child(snapshot.val()).child(firebase.auth().currentUser.uid).set({
                    createdAt: firebase.database.ServerValue.TIMESTAMP
                  }).then(() => {
                    history.pushState({}, null, '/')
                  })
                }
              })
            }
            if (analytics) {
              analytics.identify(this.user.uid)
            }
            this.pageUser()
          }).catch((error) => {
            console.log(error)
            this.$f7.preloader.hide()
            if (error.message) {
              this.$f7.toast.create({
                icon: '<i class="icon-warning"></i>',
                text: error.message,
                position: 'center',
                cssClass: 'warning',
                closeTimeout: 6000,
                closeButton: true,
              }).open()
            }
          })
        }
      })
    },
    signOut() {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        this.user = null
        this.session = null
        document.location = document.location
      }).catch((error) => {
        // An error happened.
      })
    },
    saveSignInUser(data) {
      this.session = data.user
      this.user = data.user
      this.loadUser()
      if (data.additionalUserInfo.isNewUser) {
        this.addCredits('saved-email')
        this.generateShareLink()
      }
    },
    emailSignIn() {
      if (this.emailForSignIn) {
        this.signInWithEmail(this.emailForSignIn)
      }
    },
    googleSignIn() {
      var provider = new firebase.auth.GoogleAuthProvider()
      this.signInWithProvider(provider)
    },
    facebookSignIn() {
      var provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope('email');
      this.signInWithProvider(provider)
    },
    signInWithProvider(provider) {
      this.$f7.preloader.show()
      firebase.auth().signInWithPopup(provider).then((data) => {
        if (typeof data.user != 'undefined' && typeof data.user.uid != 'undefined') {
          this.saveSignInUser(data)
          this.$f7.preloader.hide()
          this.$f7.popup.close('.popup-login')
        }
      }).catch(err => {
        console.log(err)
        if (err.message) {
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: err.message,
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000
          }).open()
        }
        this.$f7.preloader.hide()
      })
    },
    signInWithEmail(email) {
      if (!email) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: this.$t('please-enter-email'),
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
        return
      }
      this.$f7.preloader.show()
      let actionCodeSettings = {
        url: document.location.port == '8080' ? 'http://localhost:8080' : 'https://' + document.location.hostname,
        handleCodeInApp: true,
        // dynamicLinkDomain: 'example.page.link'
      }

      firebase.auth().fetchSignInMethodsForEmail(email).then((signInMethods) => {
        // console.log(signInMethods)
        if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1 || signInMethods.length == 0) {
          firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then((result) => {
            // console.log('Sent signin link to email')
            window.localStorage.setItem('emailForSignIn', email)
            let msg = signInMethods.length == 0 ? this.$t('signup-link-email') : this.$t('login-link-email')
            this.$f7.toast.create({
              text: msg,
              position: 'center',
              closeTimeout: 10000,
            }).open()
            this.$f7.preloader.hide()
            this.$f7.popup.close('.popup-login')
          }).catch((err) => {
            console.log(err)
            if (err.message) {
              if (err.message) {
                this.$f7.toast.create({
                  icon: '<i class="icon-warning"></i>',
                  text: err.message,
                  position: 'center',
                  cssClass: 'warning',
                  closeTimeout: 6000,
                  closeButton: true,
                }).open()
              }
            }
            this.$f7.preloader.hide()
          })
        }
        else if (signInMethods.indexOf(firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD) != -1) {
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: this.$t('already-registered-google'),
            position: 'center',
            closeTimeout: 6000,
            closeButton: true,
          }).open()
          this.$f7.preloader.hide()
        }
        else if (signInMethods.indexOf(firebase.auth.FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD) != -1) {
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: this.$t('already-registered-facebook'),
            position: 'center',
            closeTimeout: 6000,
            closeButton: true,
          }).open()
          this.$f7.preloader.hide()
        }
        else {
          // console.log(signInMethods)
          this.$f7.preloader.hide()
        }

      })
    },
    initSignInWithEmailLink() {
      // console.log('initSignInWithEmailLink')
      const app = this.$f7
      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let emailForSignIn = window.localStorage.getItem('emailForSignIn')
        // console.log('emailForSignIn', emailForSignIn)

        if (!emailForSignIn) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          emailForSignIn = window.prompt('Please provide your email for confirmation')
        }

        firebase.auth().fetchSignInMethodsForEmail(emailForSignIn).then((signInMethods) => {
          // console.log('signInMethods', signInMethods)
          if (signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
            //Use signin link to login
            firebase.auth().signInWithEmailLink(emailForSignIn, window.location.href).then((data) => {
              // console.log('signInWithEmailLink',data)
              if (data.user) {
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn')
                // console.log(data.user)
                this.saveSignInUser(data)
                this.generateShareLink()
              }
              else {
                this.$f7.toast.create({
                  icon: '<i class="icon-warning"></i>',
                  text: 'Could not sign you in. Please try again',
                  position: 'center',
                  cssClass: 'warning',
                  closeTimeout: 6000
                }).open()
              }

              history.pushState({}, null, '/')
            })
              .catch(err => {
                console.log(err)
                if (err.message) {
                  this.$f7.toast.create({
                    icon: '<i class="icon-warning"></i>',
                    text: err.message,
                    position: 'center',
                    cssClass: 'warning',
                    closeTimeout: 6000
                  }).open()
                }
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
              })
          }
          else if (signInMethods.length == 0) {
            // Link account for new signups

            // The client SDK will parse the code from the link for you.
            // Construct the email link credential from the current URL.
            let credential = firebase.auth.EmailAuthProvider.credentialWithLink(
              emailForSignIn, window.location.href)

            // Link the credential to the current user.
            firebase.auth().currentUser.linkWithCredential(credential)
              .then(data => {
                // console.log('linkWithCredential',data)
                if (data.user) {
                  // Clear email from storage.
                  window.localStorage.removeItem('emailForSignIn')

                  this.saveSignInUser(data)
                  this.saveUser()
                  this.addCredits('saved-email')

                  this.generateShareLink()
                }
                else {
                  this.$f7.toast.create({
                    icon: '<i class="icon-warning"></i>',
                    text: 'Could not sign you in. Please try again',
                    position: 'center',
                    cssClass: 'warning',
                    closeTimeout: 6000
                  }).open()
                }

                // window.history.pushState({}, document.title, '/')
                history.pushState({}, null, '/')

                // The provider is now successfully linked.
              }).catch(err => {
                // Some other error occurred.
                console.log(err)
                if (err.message) {
                  this.$f7.toast.create({
                    icon: '<i class="icon-warning"></i>',
                    text: err.message,
                    position: 'center',
                    cssClass: 'warning',
                    closeTimeout: 6000
                  }).open()
                }
                //history.pushState({}, null, '/')

              })
          }
        }).catch(err => {
          // Some other error occurred.
          console.log(err)
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: 'Could not sign you in. Please try again',
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000
          }).open()
        })
      }
    },


    initCompanySearch() {
      const self = this
      const app = this.$f7
      // console.log('initCompanySearch')
      if (app.autocomplete.get('#autocomplete-company')) {
        // Already loaded
        return
      }

      // Dropdown with data
      this.companyDropdown = app.autocomplete.create({
        inputEl: '#autocomplete-company',
        openIn: 'dropdown',
        preloader: false, // enable preloader
        /* If we set valueProperty to "id" then input value on select will be set according to this property */
        valueProperty: 'domain', // object's "value" property name
        textProperty: 'name', // object's "text" property name
        limit: 10, // limit to 20 results
        highlightMatches: false,
        // dropdownPlaceholderText: 'Enter a company name...',
        source(query, render) {
          const autocomplete = this
          const results = []
          if (query.length === 0) {
            render(results)
            return
          }
          // Show Preloader
          autocomplete.preloaderShow()
          // Do Ajax request to Autocomplete data
          app.request({
            url: 'https://autocomplete.clearbit.com/v1/companies/suggest',
            method: 'GET',
            dataType: 'json',
            // send "query" to server. Useful in case you generate response dynamically
            data: {
              query: query,
            },
            success(data) {
              // Find matched items
              for (let i = 0; i < data.length; i += 1) {
                // if (data[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
                let title = '<img src="' + data[i].logo + '" onerror="this.src=\'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=\'">' + '<span class="name">' + data[i].name + '</span> <span class="domain">' + data[i].domain + '</span>'
                results.push({
                  'name': title,
                  'org': data[i].name,
                  'domain': data[i].domain,
                  'logo': data[i].logo
                })
                // }
              }
              if (data.length == 0 && results.length == 0 && self.validateURL(this.data.query)) {
                let shortUrl = this.data.query
                shortUrl = shortUrl.replace(/(https|http):\/\/(www\.)?/, '')
                if (shortUrl.length > 27) {
                  shortUrl = shortUrl.substring(0, 26) + '...'
                }
                let title = '<span class="name">' + self.$t('website-url-get') + shortUrl + '</span>'
                results.push({
                  'name': title,
                  'org': this.data.query,
                  'domain': this.data.query,
                  'logo': ''
                })
              }
              // Hide Preoloader
              autocomplete.preloaderHide()
              // Render items by passing array with result items
              render(results)
            },
          })
        },
        on: {
          change: (item) => {
            if (item && item.length > 0) {

              self.currentProject.companyName = item[0].org
              self.currentProject.companyDomain = item[0].domain
              if (!self.currentProject.title && self.currentProject.companyName) {
                self.currentProject.title = self.currentProject.companyName
              }
              let url = item[0].domain
              if (url.indexOf('http:') == -1 && url.indexOf('https:') == -1) {
                url = 'https://www.' + url
              }
              if (self.validateURL(url)) {
                self.showProgressbar('.website-progress', 5)
                self.fetchWebsite(url).then(img => {
                  this.websiteImage = img
                }).catch(err => {
                  console.log(err)
                })
                self.showProgressbar('.icon-progress', 25)
                self.fetchLogo(url).then(img => {
                  this.logoImage = img
                  this.addCredits('add-company-logo')
                }).catch(err => {
                  console.log(err)
                  if (self.validateURL(item[0].logo)) {
                    let url = item[0].logo
                    this.toDataURL(url, imgData => {
                      self.logoImage = imgData
                    })
                  }
                })
                // self.updateProject({ companyName: item[0].org, companyDomain: item[0].domain })
              }
            }
          }
        }
      })

    },
    showProgressbar(id, speed) {
      const self = this
      let progressBarEl
      progressBarEl = this.$f7.progressbar.show(id, 0)
      let progress = 0
      function simulateLoading() {
        setTimeout(() => {
          const progressBefore = progress
          progress += Math.random() * speed
          self.$f7.progressbar.set(progressBarEl, progress)
          if (progressBefore < 100) {
            simulateLoading() // keep "loading"
          } else {
            self.$f7.progressbar.hide(progressBarEl); // hide
          }
        }, Math.random() * 200 + 200)
      }
      simulateLoading()
    },
    playExplainer() {
      let player = this.$$('#explainer-video')[0]
      if (player.paused) {
        player.play()
      }
      else {
        player.pause()
      }
      this.showExplainer = true
      if (!this.isMobile) {
        setTimeout(() => {
          this.addCredits('watched-explainer')
        }, 1000)
      }
    },
    createShortLink(code) {
      return firebase.database().ref('links').child(code).limitToFirst(1).once("value")
    },
    getShortLink(charLen) {
      let code = firebase.auth().currentUser.uid.toLowerCase()
      code = code.substring(code.length - charLen)
      this.createShortLink(code).then(snapshot => {
        if (snapshot.exists()) {
          return this.getShortLink(charLen + 1)
        } else {
          firebase.database().ref('links').child(code).child(firebase.auth().currentUser.uid).set(true).then(data => {
            // console.log(code + ' created')
            this.user.shareLink = code
            this.saveUser()
            // console.log('got link', this.user.shareLink)
            return code
          })
        }
      })
    },
    generateShareLink() {
      var promise = new Promise((resolve, reject) => {
        if (this.user && !this.user.shareLink) {
          let minLen = 4
          resolve(this.getShortLink(minLen))
        }
        else {
          resolve(this.user.shareLink)
        }
      })
      return promise
    },
    generateShortLink() {
      var promise = new Promise((resolve, reject) => {
        if (this.user && !this.user.shareLink) {
          // let host = document.location.port == '8080' ? 'http://localhost:8080' : 'https://' + document.location.hostname
          let host = 'https://getthumb.page.link'
          let postData = {
            'suffix': {
              'option': 'SHORT'
            },
            'dynamicLinkInfo': {
              'domainUriPrefix': host,
              'link': host + '/?invite=' + encodeURI(this.userId),
              'androidInfo': {
                'androidPackageName': 'me.thumbify.app'
              },
              'socialMetaTagInfo': {
                'socialTitle': this.user.displayName,
                'socialDescription': 'Thumbify.me - Create your video thumbnails in seconds',
                'socialImageLink': ''
              },
              'iosInfo': {
                'iosBundleId': 'me.thumbify.app'
              }
            }
          }
          this.$f7.request.postJSON('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' + firebaseConfig.apiKey, postData, function (data) {
            this.user.shareLink = data.shortLink
            this.saveUser()
            resolve(data)
          }, function (xhr, status) {
            console.log(xhr, status)
            reject(false)
          })
        }
        else {
          resolve(this.user.shareLink)
        }
      })
      return promise
    },

    sendInvite(type) {
      var invite = {}
      invite.event_id = this.current_event
      invite.url = this.invite_url
      invite.subject = this.$t('events.invitationsubject')

      // Validate
      if (type == 'email') {
        window.open('mailto:?subject=' + invite.subject + '&body=%0D%0A%0D%0A' + invite.url, '_system')
      }
      else if (type == 'sms') {
        window.open('sms:&body=%0D%0A' + invite.url, '_system')
      }
    },
    pageInit(e) {
      let params = e.router.currentRoute.params
      let query = e.router.currentRoute.query
      if (params && params.projectId) {
        this.currentProject.id = params.projectId
        this.currentProject.userId = params.userId
        this.wizardStep = 1
      }
      if (query && query.i) {
        this.inviteLinkId = query.i
      }
    },
    pageUser() {
      // Loaded after page load and user login

      // Handle project url
      if (this.$f7router.currentRoute.path == '/new' && !this.isMobile) {
        this.createProject()
      }
      if (this.$f7router.currentRoute.path == '/remove-image-background-free' && !this.isMobile) {
        this.showBGRemover = true
        let title = 'Thumbify.me - Remove Image Background For Free'
        let metakeywords = 'background remover, remove background from image, image background removal, free thumbnail maker, free video thumbnail maker, free video thumbnail generator, free video thumbnail creator, creating a video thumbnail, how to create a video thumbnail, video thumbnail guide, video thumbnail tips, how to make a video thumbnail'
        let metadescription = 'Remove background from your uploaded image in a few seconds. Create transparent background of your images for free. Add new backgrounds with our smart AI Background Remover.'
        document.title = title
        document.head.querySelector('meta[name="description"]').setAttribute('content', metadescription)
        document.head.querySelector('meta[name="keywords"]').setAttribute('content', metakeywords)
      }

    },
    tooltipInit() {
      const app = this.$f7
      setTimeout(() => {
        this.$$('.tooltip-init').each((index, targetEl) => {
          app.tooltip.create({
            targetEl: targetEl,
            text: this.$$(targetEl).attr('data-tooltip'),
          })
        })
      }, 100)
    },
    useReveal(e) {
      console.log(e)
    },
    initReveal() {
      // window.useReveal = this.useReveal
      // document.write('<scr' + 'ipt defer src="https://reveal.clearbit.com/v1/companies/reveal?authorization=pk' + '_a7bb4cb2fcda3017eb148' + 'eeffe8438d7&callback=useReveal"></scr' + 'ipt>')
    },
    initAnalytics() {
      const fbanalytics = firebase.analytics()
    },
    quickStart() {
      this.$$('.page-content').scrollTop(window.innerHeight, 600)
    },
    calculateSize() {
      // console.log(this.$$('.main-card')[0].offsetHeight * 0.66)
      // let availHeight = window.innerHeight - 300
      // if (availHeight < 360){
      //   let ratio = this.canvas.settings.width / this.canvas.settings.height
      // this.canvas.settings.height = availHeight
      // this.canvas.settings.width = this.canvas.settings.height * ratio
      // }
    },
    detectMobile() {
      if (window.innerWidth < 840) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  mounted() {
    this.signIn()
    this.initWatermark()
    this.detectMobile()
    this.initAnalytics()
    // this.initReveal()
    // setTimeout(() => {
    //   this.addCredits('created-first-project')
    // }, 2000)
    window.addEventListener('resize', this.detectMobile)
    if (Konva) {
      Konva.showWarnings = document.location.host == 'localhost:8080'
    }
    // Navigate from landingpage
  },
  created() {
    this.calculateSize()
    this.initDB()
    let title = 'Thumbify.me - Free Video Thumbnail & Virtual Background Generator'
    document.title = title
  }
}
</script>