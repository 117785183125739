<template>
  <f7-list-item
    :title="title"
    @click="setPlayButton(index)"
  >
    <img
      slot="media"
      :src="'./static/images/play/' + index + '.png'"
      :alt="title"
    />
  </f7-list-item>
</template>
<script>
export default {
  props: {
    title: String,
    index: String
  },
  methods: {
    setPlayButton(index) {
      this.$emit('setPlayButton', index)
    },
  }
}
</script>