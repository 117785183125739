<template>
  <div>
    <f7-card
      :style="
        'background-image:url(' +
        (project.thumbUrl ? project.thumbUrl : './static/images/bg.png') +
        ')'
      "
    >
      <f7-link class="card-link" @click="loadProject(project)">
        <f7-card-header
          class="no-border"
          valign="bottom"
          v-html="formatProjectTitle(project)"
        >
        </f7-card-header>
        <f7-card-content> </f7-card-content>
      </f7-link>

      <f7-card-footer>
        <f7-link @click="loadProject(project)" style="width: 40%">
          {{ $t("open") }}
        </f7-link>
        <f7-link
          @click="quickEditProject(project)"
          icon="icon-link"
          :tooltip="$t('quick-edit')"
        ></f7-link>
        <f7-link
          @click="downloadProject(project)"
          icon="icon-download"
          style="margin-left: 2em; margin-right: 2em"
          :tooltip="$t('download-project')"
        ></f7-link>
        <f7-link
          @click="deleteProject(project)"
          icon="icon-trash"
          :tooltip="$t('remove-project-title')"
        ></f7-link>
      </f7-card-footer>
    </f7-card>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: {
    project: Object
  },
  methods: {
    loadProject(project) {
      this.$emit('loadProject', project)
    },
    downloadProject(project) {
      this.$emit('downloadProject', project)
    },
    quickEditProject(project) {
      this.$emit('quickEditProject', project)
    },
    deleteProject(project) {
      this.$emit('deleteProject', project)
    },
    formatProjectTitle(project) {
      if (project.title) {
        return project.title
      }
      else if (project.companyName) {
        return project.companyName + '-thumb'
      }
      else {
        return '<i>' + this.$t('unsaved-project') + ' ' + moment(project.createdAt).format('LT') + '</i>'
      }
    },
  }
}
</script>