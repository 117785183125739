<template>
  <f7-card
    :style="
      'background-image:url(' +
      (item.thumbUrl ? item.thumbUrl : './static/images/bg.png') +
      ')'
    "
  >
    <f7-link class="card-link" @click="selectLibraryImage(item)">
      <f7-card-content> </f7-card-content>
    </f7-link>
    <f7-card-footer>
      <f7-link @click="selectLibraryImage(item)" style="width: 100%">
        <div class="type">{{ $t(item.type) }}</div>
        <div class="date">{{ fixShortDate(item.createdAt) }}</div>
      </f7-link>
      <f7-link
        @click="deleteLibraryImage(item)"
        icon="icon-trash"
        :tooltip="$t('delete-image')"
      ></f7-link>
    </f7-card-footer>
  </f7-card>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    item: Object
  },
  methods: {
    fixShortDate(datetime) {
      return moment(datetime).format('LL')
    },
    selectLibraryImage(item) {
      this.$emit('selectLibraryImage', item)
    },
    deleteLibraryImage(item) {
      this.$emit('deleteLibraryImage', item)
    },
  }
}
</script>