<template>
    <div
      class="cover tooltip-init"
      :style="`background-image:url(` + url + `)`"
      :data-tooltip="title"
      @click="addPerson(url)"
    />
</template>
<script>
export default {
  props: {
    url: String,
    title: String
  },
  methods: {
    addPerson(url) {
      this.$emit('addPerson', url)
    },
  }
}
</script>