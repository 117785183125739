// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/fonts.css';
import '../css/app.css';

// Import Konva
import VueKonva from 'vue-konva'
Vue.use(VueKonva)

// Import webcam
import WebCam from 'vue-web-cam';
Vue.use(WebCam)

// Import comparer
import VueCompareImage from 'vue-compare-image';
Vue.component('VueCompareImage', VueCompareImage);
// Vue.use(VueCompareImage)

// Import GTM
import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: "GTM-TXLRGN4",
  //defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  //enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  //debug: true, // Whether or not display console logs debugs (optional)
  //loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  //trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});


// Import App Component
import App from '../components/app.vue';

// Import Custom Components
import CreditChip from '../components/creditchip.vue';
Vue.component('creditchip', CreditChip);
import ProjectList from '../components/projectlist.vue';
Vue.component('projectlist', ProjectList);
import BackgroundList from '../components/backgroundlist.vue';
Vue.component('backgroundlist', BackgroundList);
import ButtonList from '../components/buttonlist.vue';
Vue.component('buttonlist', ButtonList);
import StickerList from '../components/stickerlist.vue';
Vue.component('stickerlist', StickerList);
import FontList from '../components/fontlist.vue';
Vue.component('fontlist', FontList);
import PersonList from '../components/personlist.vue';
Vue.component('personlist', PersonList);
import CreditList from '../components/creditlist.vue';
Vue.component('creditlist', CreditList);
import LibraryPopup from '../components/librarypopup.vue';
Vue.component('librarypopup', LibraryPopup);
import LibraryList from '../components/librarylist.vue';
Vue.component('librarylist', LibraryList);
import BGLibraryPopup from '../components/bglibrarypopup.vue';
Vue.component('bglibrarypopup', BGLibraryPopup);
import BGLibraryList from '../components/bglibrarylist.vue';
Vue.component('bglibrarylist', BGLibraryList);
import WebcamPopup from '../components/webcampopup.vue';
Vue.component('webcampopup', WebcamPopup);
import CopyPopup from '../components/copypopup.vue';
Vue.component('copypopup', CopyPopup);
import CopyEmailPopup from '../components/copyemailpopup.vue';
Vue.component('copyemailpopup', CopyEmailPopup);
import LoginPopup from '../components/loginpopup.vue';
Vue.component('loginpopup', LoginPopup);
import DebugPopover from '../components/debugpopover.vue';
Vue.component('debugpopover', DebugPopover);

// Import Translations
import { messages } from './translations'
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
let locales = ['en'];
let userLocale = navigator.language.substr(0, 2)
if (!locales.includes(userLocale)) {
  userLocale = 'en'
}
window.userLocale = userLocale
document.documentElement.lang = userLocale

const i18n = new VueI18n({
  locale: userLocale, // set locale
  messages, // set locale messages
});

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

Vue.config.devtools = document.location.host == 'localhost:8080'
Vue.config.productionTip = false

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
  i18n
});