let firebaseConfig;

if (document.location.host == 'thumbify.me' || document.location.host == 'localhost:80801') {
  // Prod
  firebaseConfig = {
    apiKey: "AIzaSyCXviBan3m7BrUSCADhrsys71XvzPzeU3w",
    authDomain: "thumbify.me",
    databaseURL: "https://thumbify-me-default-rtdb.firebaseio.com",
    projectId: "thumbify-me",
    storageBucket: "thumbify-me.appspot.com",
    publicBucket: "i-thumbify-me",
    publicHost: 'https://i.thumbify.me/',
    messagingSenderId: "567663501653",
    appId: "1:567663501653:web:bf93b0cd8aeb9ae6b9cfdc"
  }
}
else {
  // Dev
  firebaseConfig = {
    apiKey: "AIzaSyAl_ofWbupv0AXvblypONecH3lhhANhIGk",
    authDomain: "getthumb.web.app",
    databaseURL: "https://getthumb-default-rtdb.firebaseio.com",
    projectId: "getthumb",
    storageBucket: "getthumb.appspot.com",
    publicBucket: "i-getthumb",
    publicHost: 'https://storage.googleapis.com/i-getthumb/',
    messagingSenderId: "215056044951",
    appId: "1:215056044951:web:1903e7296bea4160172ad2"
  }
}

export { firebaseConfig }
