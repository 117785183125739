<template>
  <f7-popup
    class="popup-bglibrary"
    swipe-to-close="to-bottom"
    swipe-handler=".swipe-handler"
    @popup:open="listBackgrounds()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t("background-library") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close :tooltip="$t('close')">
            <f7-icon icon="icon-close"></f7-icon>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block>
        <div class="project-list library-list">
          <bglibrarylist
            v-for="(item, index) in libraryList"
            :key="index"
            v-bind:item="item"
            v-on:selectBackgroundImage="selectBackgroundImage"
            v-on:deleteBackgroundImage="deleteBackgroundImage"
          />
        </div>
        <f7-block v-if="Object.keys(libraryList).length == 0 && libraryLoaded">
          {{ $t("background-library-empty") }}
        </f7-block>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
export default {
  data() {
    return {
      libraryList: {},
      libraryLoaded: false
    }
  },
  props: {
  },
  methods: {
    selectBackgroundImage(image) {
      this.$emit('selectBackgroundImage', image)
    },
    listBackgrounds() {
      function reverseObject(object) {
        var newObject = {}
        var keys = []
        for (var key in object) {
          keys.push(key)
        }
        for (var i = keys.length - 1; i >= 0; i--) {
          var value = object[keys[i]]
          newObject[keys[i]] = value
        }
        return newObject
      }

      if (firebase.auth().currentUser && firebase.auth().currentUser.uid) {
        firebase.database().ref('backgrounds').child(firebase.auth().currentUser.uid).on('value', snapshot => {
          let list = {}
          snapshot.forEach(childSnapshot => {
            list[childSnapshot.key] = childSnapshot.val()
            list[childSnapshot.key].id = childSnapshot.key
          })
          this.libraryList = reverseObject(list)
        })
      }
      this.libraryLoaded = true
    },
    deleteBackgroundImage(image) {
      let fullPath = 'backgrounds/' + firebase.auth().currentUser.uid + '/'
      this.$emit('deleteFile', fullPath, image.fileName)

      // Delete database item
      let dbRef = firebase.database().ref('backgrounds').child(firebase.auth().currentUser.uid).child(image.id)
      dbRef.remove().then(() => {
        this.listBackgrounds()
      }).catch(error => {
        console.log(error)
      })

    },
  }
}
</script>