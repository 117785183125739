<template>
  <f7-popup
    class="popup-library"
    swipe-to-close="to-bottom"
    swipe-handler=".swipe-handler"
    @popup:open="listLibrary()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t("photo-library") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close :tooltip="$t('close')">
            <f7-icon icon="icon-close"></f7-icon>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block>
        <div class="project-list library-list">
          <librarylist
            v-for="(item, index) in libraryList"
            :key="index"
            v-bind:item="item"
            v-on:selectLibraryImage="selectLibraryImage"
            v-on:deleteLibraryImage="deleteLibraryImage"
          />
        </div>
        <f7-block v-if="Object.keys(libraryList).length == 0 && libraryLoaded">
          {{ $t("library-empty") }}
        </f7-block>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
export default {
  data() {
    return {
      libraryList: {},
      libraryLoaded: false
    }
  },
  props: {
  },
  methods: {
    selectLibraryImage(image) {
      this.$emit('selectLibraryImage', image)
    },
    listLibrary() {
      function reverseObject(object) {
        var newObject = {}
        var keys = []
        for (var key in object) {
          keys.push(key)
        }
        for (var i = keys.length - 1; i >= 0; i--) {
          var value = object[keys[i]]
          newObject[keys[i]] = value
        }
        return newObject
      }

      if (firebase.auth().currentUser && firebase.auth().currentUser.uid) {
        firebase.database().ref('library').child(firebase.auth().currentUser.uid).on('value', snapshot => {
          let list = {}
          snapshot.forEach(childSnapshot => {
            list[childSnapshot.key] = childSnapshot.val()
            list[childSnapshot.key].id = childSnapshot.key
          })
          this.libraryList = reverseObject(list)
        })
      }
      this.libraryLoaded = true
    },
    deleteLibraryImage(image) {
      let fullPath = 'library/' + firebase.auth().currentUser.uid + '/'
      this.$emit('deleteFile', fullPath, image.fileName)

      // Delete database item
      let dbRef = firebase.database().ref('library').child(firebase.auth().currentUser.uid).child(image.id)
      dbRef.remove().then(() => {
        this.listLibrary()
      }).catch(error => {
        console.log(error)
      })

    },
  }
}
</script>