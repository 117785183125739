<template>
  <f7-list-item
    checkbox
    disabled
    :checked="font == selected"
    @click="setFontFamily(font)"
  >
    <div slot="title" :style="'font-family:' + font + ';'" v-text="font"></div>
  </f7-list-item>
</template>
<script>
export default {
  props: {
    font: String,
    selected: String
  },
  methods: {
    setFontFamily(font) {
      this.$emit('setFontFamily', font)
    },
  }
}
</script>