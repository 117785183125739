
import HomePage from '../pages/home.vue';
import GeneratorPage from '../pages/generator.vue';
import CreateVideoPage from '../pages/blog/video-thumbnail.vue';
import CreateYoutubePage from '../pages/blog/youtube-video-thumbnail.vue';
import CreateVimeoPage from '../pages/blog/vimeo-video-thumbnail.vue';
import PrivacyPage from '../pages/privacy.vue';
import TermsPage from '../pages/terms.vue';

var routes = [
  {
    name: 'project',
    path: '/:userId/:projectId/edit',
    component: HomePage
  },
  // {
  //   name: 'invite',
  //   path: '/\?i=:inviteId',
  //   component: HomePage
  // },
  {
    path: '/how-to-make-a-video-thumbnail',
    component: CreateVideoPage,
  },
  {
    path: '/how-to-make-a-youtube-video-thumbnail',
    component: CreateYoutubePage,
  },
  {
    path: '/how-to-make-a-vimeo-video-thumbnail',
    component: CreateVimeoPage,
  },
  {
    path: '/remove-image-background-free',
    component: HomePage,
  },
  {
    path: '/privacy',
    component: PrivacyPage,
  },
  {
    path: '/terms',
    component: TermsPage,
  },
  {
    path: '/generator',
    component: GeneratorPage,
  },
  {
    path: '/new',
    component: HomePage,
  },
  {
    path: '/',
    component: HomePage,
  }
];

export default routes;
