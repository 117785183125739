<template>
  <div>
    <f7-chip
    icon="icon-thumbify"
    :class="'credit-tag ' + aligned"
    :text="events ? events[eventName] : amount"
    :slot="slotpos"
    :tooltip="
      $t('credit-collecting')
        .replace('XX', events ? events[eventName] : amount)
        .replace('YY', $t('events.' + eventName).toLowerCase())
    "
  ></f7-chip>
  </div>
</template>
<script>
export default {
  props: {
    eventName: String,
    events: Object,
    amount: Number,
    aligned: String,
    slotpos: String
  },
  data () {
    return {
    }
  }
}
</script>