<template>
  <f7-col
    width="50"
    class="image-background"
    @click="setBackground('bg', index)"
  >
    <div
      class="cover tooltip-init"
      :style="`background-image:url(./static/images/bg/bg-` + index + `.jpg)`"
      :data-tooltip="title"
    />
  </f7-col>
</template>
<script>
export default {
  props: {
    title: String,
    index: String
  },
  methods: {
    setBackground(type, index) {
      this.$emit('setBackground', type, index)
    },
  }
}
</script>