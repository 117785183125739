<template>
  <f7-page name="generator">
    <f7-card class="main-card" padding>
      <f7-card-content>
        <f7-row class="card-nav">
          <f7-col width="100" medium="30">
            <f7-link href="/">
              <img
                src="static/images/logo.svg"
                class="logo"
                alt="thumbify.me logo"
              />
            </f7-link>
          </f7-col>
          <f7-col width="100" medium="70">
            <h2>Thumb Generator</h2>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="100">
            <f7-block>
              <p>
                Use the textbox below to paste in a tab-separated list of data
                or use the form to add rows (example copy from Excel or Google
                sheet).
              </p>
              <div class="list-headers">
                <b
                  >firstname &nbsp;&nbsp; lastname &nbsp;&nbsp; company
                  &nbsp;&nbsp; website &nbsp;&nbsp; email &nbsp;&nbsp;
                  manager_firstname &nbsp;&nbsp; manager_lastname &nbsp;&nbsp;
                  image_url &nbsp;&nbsp; message
                </b>
                <f7-button style="float: right" @click="showListExample()">
                  Insert sample data
                </f7-button>
              </div>
              <textarea
                class="data-list"
                :value="listData"
                @change="listData = $event.target.value"
                :placeholder="getListPlaceholder()"
              ></textarea>
              <div v-if="listError" class="data-error">
                <ul>
                  <li
                    v-for="(err, index) in listError"
                    :key="index"
                    v-html="err"
                  ></li>
                </ul>
              </div>
              <f7-button
                class="generate-button"
                :disabled="processing"
                fill
                large
                @click="generateThumbs()"
              >
                Verify &amp; Generate Thumbs
              </f7-button>
            </f7-block>
          </f7-col>
        </f7-row>
        <f7-row v-if="processData">
          <f7-col width="100">
            <div class="data-table card">
              <table>
                <thead>
                  <th>#</th>
                  <th>Website</th>
                  <th>Text</th>
                  <th>Thumb</th>
                  <th>Link</th>
                </thead>
                <tbody v-if="processData">
                  <tr v-for="(row, index) in processData" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ formatWebsite(row.website) }}</td>
                    <td>{{ mergeTags(row.message,row) }}</td>
                    <td>
                      <div v-if="processRow == index">
                        <f7-progressbar infinite></f7-progressbar>
                      </div>
                      <div v-if="processImage[index]">
                        <img
                          :src="processImage[index]"
                          class="thumb-image"
                          @click="$refs.standaloneDark.open()"
                        />
                      </div>
                    </td>
                    <td>
                      <div v-if="processImage[index]">
                        <f7-link :href="row.thumbnail_url" external target="thumb">Link</f7-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="!processData">
                  <tr>
                    <td class="list-nodata">No data added</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <f7-photo-browser
              :photos="processImage"
              theme="dark"
              ref="standaloneDark"
            ></f7-photo-browser>
          </f7-col>
        </f7-row>
        <div class="userButtons">
          <f7-row v-if="!isMobile">
            <f7-col v-if="session && !session.isAnonymous">
              <f7-button @click="signOut()" v-text="$t('logout')"></f7-button>
            </f7-col>
            <f7-col>
              <f7-button href="/">
                {{ $t("show-project-library") }}
              </f7-button>
            </f7-col>
          </f7-row>
        </div>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>
  
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

import moment from 'moment'

import { firebaseConfig } from '../js/config';

export default {
  data() {
    return {
      isMobile: false,
      session: null,
      listData: null,
      listError: [],
      generatorEndpoint: document.location.port == '8080' ? 'http://localhost:5010' : 'https://' + firebaseConfig.authDomain + '/api',
      processData: null,
      processResult: [],
      processImage: [],
      processRow: 0,
      processing: false
    }
  },
  methods: {
    getListPlaceholder() {
      let placeholder = [
        'firstname	lastname	company	website	email	manager_firstname	manager_lastname	image_url	message	thumbnail_url',
        'Michael	Litt	Vidyard	https://www.vidyard.com	michael@vidyard.com	Paul	Smith		Hey {{firstname}}, {{manager_firstname}} would be impressed...',
      ]
      return placeholder.join('\n')
    },
    showListExample() {
      this.listData = this.getListPlaceholder()
    },
    setList(e) {
      console.log(e)
    },
    tsvJSON(tsv) {
      let lines = tsv.split("\n")
      let result = []
      let headers = lines[0].split("\t")
      for (let i = 1; i < lines.length; i++) {
        let obj = {}
        let currentline = lines[i].split("\t")
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j]
        }
        result.push(obj)
      }
      return result //JavaScript object
      // return JSON.stringify(result) //JSON
    },
    JSONtsv(data) {
      let array = typeof data != 'object' ? JSON.parse(data) : data
      let str = ''
      for (let i = 0; i < array.length; i++) {
        if (!str){
          //Columns
          let cols = '';
          for (let index in array[i]) {
            if (cols != ''){
              cols += '\t'
            }
            cols += index
          }
          if (cols){
            str += cols + '\n'
          }
        }
        let line = ''
        for (let index in array[i]) {
          if (line != ''){
            line += '\t'
          }
          if (array[i][index]){
            line += array[i][index]
          }
        }
        if (line){
          str += line + '\n'
        }
      }
      return str
    },

    parseCSV(str) {
      var arr = []
      var quote = false;  // 'true' means we're inside a quoted field
      // Iterate over each character, keep track of current row and column (of the returned array)
      for (var row = 0, col = 0, c = 0; c < str.length; c++) {
        var cc = str[c], nc = str[c + 1];        // Current character, next character
        arr[row] = arr[row] || [];             // Create a new row if necessary
        arr[row][col] = arr[row][col] || '';   // Create a new column (start with empty string) if necessary
        // If the current character is a quotation mark, and we're inside a
        // quoted field, and the next character is also a quotation mark,
        // add a quotation mark to the current column and skip the next character
        if (cc == '"' && quote && nc == '"') { arr[row][col] += cc; ++c; continue; }
        // If it's just one quotation mark, begin/end quoted field
        if (cc == '"') { quote = !quote; continue; }
        // If it's a comma and we're not in a quoted field, move on to the next column
        if (cc == ',' && !quote) { ++col; continue; }
        // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
        // and move on to the next row and move to column 0 of that new row
        if (cc == '\r' && nc == '\n' && !quote) { ++row; col = 0; ++c; continue; }
        // If it's a newline (LF or CR) and we're not in a quoted field,
        // move on to the next row and move to column 0 of that new row
        if (cc == '\n' && !quote) { ++row; col = 0; continue; }
        if (cc == '\r' && !quote) { ++row; col = 0; continue; }
        // Otherwise, append the current character to the current column
        arr[row][col] += cc
      }
      return arr;
    },

    verifyData(data) {
      for (let row in data) {
        if (data[row].website && data[row].website.indexOf('http') == -1 && data[row].website.indexOf('www') == -1) {
          this.listError.push('Row ' + (parseInt(row) + 1) + ', column 1 should have a valid webaddress')
          return false
        }
        if (!data[row].website || !data[row].firstname || !data[row].lastname || !data[row].message) {
          console.log(data[row])
          this.listError.push('Row ' + (parseInt(row) + 1) + ' does not contain mandatory values')
          return false
        }
      }
      return true
    },
    mergeTags(message, row) {
      for (let col in row) {
        message = message.replace('{{' + col + '}}', row[col])
      }
      return message
    },
    formatWebsite(website){
      return website.replace('https://www.','').replace('http://www.','').replace('https://','').replace('http://','')
    },
    processNext() {
      var promise = new Promise((resolve, reject) => {
        let app = this.$f7
        let row = this.processData[this.processRow]
        let profilesearch = [row.firstname, row.lastname, row.company].join(' ')
        let message = this.mergeTags(row.message, row)
        let postData = {
          'website': row.website,
          'profile_search': profilesearch,
          'image_url': row.image_url,
          'text': message
        }
        // Simulate
        // setTimeout(()=>{
        //   // Next?
        //   this.processRow++
        //   console.log(this.processRow, this.processData.length)
        //   if (this.processRow == this.processData.length){
        //     resolve('Done')
        //   }
        //   else{
        //     console.log('next')
        //     return this.processNext().then(()=>{
        //       resolve('Done2')
        //     })
        //   }
        // }, 3000)
        let postOptions = {
          url: this.generatorEndpoint + '/generator',
          data: postData,
          method: 'POST',
          contentType: 'application/json',
          crossDomain: true,
          dataType: 'json'
        }
        app.request.promise(postOptions).then((res) => {
          if (res.data) {
            // this.processResult[this.processRow] = res.data
            let dataUrl = 'data:image/png;base64,' + res.data.img
            this.processImage[this.processRow] = dataUrl
            let imageId = new Date().getTime()
            let imagePath = firebase.auth().currentUser.uid + '/generator/' + imageId + '.jpg'
            let self = this
            let rowId = this.processRow
            this.storePublicThumb(imagePath, dataUrl).then(downloadUrl => {
              console.log(downloadUrl)
              let publicUrl = firebaseConfig.publicHost + imagePath
              console.log(publicUrl)
              self.processData[rowId].thumbnail_url = publicUrl

              // Next?
              this.processRow++
              if (this.processRow == this.processData.length) {
                resolve()
              }
              else {
                return this.processNext().then(() => {
                  resolve('Done')
                })
              }
            })
          }
          else{
            this.$f7.toast.create({
              icon: '<i class="icon-warning"></i>',
              text: 'Something wen\'t wrong when generating row ' + (this.processRow + 1) + '\r - Check the data and try again',
              position: 'center',
              cssClass: 'warning',
              closeTimeout: 6000,
              closeButton: true,
            }).open()
            this.processing = false
            resolve('Done')
          }
          
        }).catch((err) => {
          console.log(err)
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: 'Something wen\'t wrong when generating row ' + (this.processRow + 1) + '\r',
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000,
            closeButton: true,
          }).open()
          this.processing = false
          reject(err)
        })
      })
      return promise
    },

    generateThumbs() {
      this.listError = []
      if (!this.listData) {
        this.listError.push('The list doesn\'t contain any valid rows')
        return
      }

      let data = this.tsvJSON(this.listData.trim())
      
      if (this.verifyData(data)) {
        this.processData = data
        this.processResult = []
        this.processImage = []
        this.processing = true
        this.processRow = 0
        this.processNext().then(status => {
          this.listData = this.JSONtsv(this.processData)
          this.processing = false
        }).catch(err => {
          console.log(err)
        })
      }
    },

    storePublicThumb(imagePath, fileData) {
      var promise = new Promise((resolve, reject) => {
        let storageRef = firebase.app().storage(firebaseConfig.publicBucket).ref(imagePath)
        let uploadTask = storageRef.putString(fileData, 'data_url')
        uploadTask.on('state_changed', (snapshot) => {
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + progress + ' done')
        }, (error) => {
          console.log(error)
          reject(error)
        }, () => {
          let downloadURL = firebaseConfig.publicHost + imagePath
          resolve(downloadURL)
        })
      })
      return promise
    },

    initDB() {
      // Initialize Firebase
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig)
      }
    },
    signIn() {
      this.$f7.preloader.show()
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // console.log('existing user found', user)
          this.session = user
          this.$f7.preloader.hide()
        }
        else {
          this.$f7.toast.create({
            icon: '<i class="icon-warning"></i>',
            text: 'You must be logged in',
            position: 'center',
            cssClass: 'warning',
            closeTimeout: 6000
          }).open()
          this.$f7.preloader.hide()
        }
      })
    },
    signOut() {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        this.user = null
        this.session = null
        document.location = document.location
      }).catch((error) => {
        // An error happened.
      })
    },
    detectMobile() {
      if (window.innerWidth < 840) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
  },
  mounted() {
    this.signIn()
  },
  created() {
    this.initDB()
    let title = 'Thumbify.me - Free Video Thumbnail & Virtual Background Generator'
    document.title = title
  }
}

</script>
<style scoped>
.list-headers {
  margin-top: 1em;
}
.data-error {
  color: #ee8888;
  font-weight: bold;
}
.generate-button {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2em;
}
.data-list {
  width: 100%;
  height: 10em;
  font-size: 0.8em;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin: 1em 0;
  padding: 0.3em;
}
.data-table.card {
  border-radius: 0.3em;
  border: 1px solid #f5f5f5;
}
.data-table .list-nodata {
  color: #999999;
  font-style: italic;
  padding: 1em;
}
.data-table .thumb-image {
  height: 6em;
  margin-top: 0.4em;
  border-radius: 6px;
}
</style>