<template>
  <f7-list-item
    @click="addSticker(id)"
  >
    <img
      :src="url"
      :alt="title"
    />
  </f7-list-item>
</template>
<script>
export default {
  props: {
    id: String,
    url: String,
    title: String
  },
  methods: {
    addSticker(id) {
      this.$emit('addSticker', id)
    },
  }
}
</script>