<template>
  <f7-popup
    class="copypopup popup-copyemail"
    swipe-to-close="to-bottom"
    swipe-handler=".swipe-handler"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t("copy-email") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close :tooltip="$t('close')">
            <f7-icon icon="icon-close"></f7-icon>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block>
        <f7-block-title medium> {{ $t("thumbnail-copied") }} </f7-block-title>
        <f7-row>
          <f7-col width="100">{{ $t("thumbnail-linked") }}</f7-col>
        </f7-row>
      </f7-block>
      <f7-block class="copy-shortcuts">
        <f7-row>
          <f7-col>{{ $t("paste-link-mac") }}: </f7-col>
          <f7-col>{{ $t("paste-link-windows") }}: </f7-col>
        </f7-row>
        <f7-row>
          <f7-col><f7-chip text="Command+V"></f7-chip></f7-col>
          <f7-col><f7-chip text="Ctrl+V"></f7-chip></f7-col>
        </f7-row>
      </f7-block>
      <f7-block class="copy-buttons">
        <p v-text="$t('open-mail-description')"></p>
        <f7-row>
          <f7-col>
            <f7-button @click="openMail()">
              <img src="static/images/macmail.svg" />
              {{ $t("open-macmail") }}
            </f7-button>
          </f7-col>
          <f7-col>
            <f7-button @click="openGmail()">
              <img src="static/images/gmail.svg" />
              {{ $t("open-gmail") }}
            </f7-button>
          </f7-col>
          <f7-col>
            <f7-button @click="openMail()">
              <img src="static/images/outlook.svg" />
              {{ $t("open-outlook") }}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>
<script>
export default {
  props: {
  },
  methods: {
    openGmail() {
      let url = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=' + '&su=' + '&body=' //+ encodeURIComponent(this.sharingCode)
      window.open(url, '_system')
    },
    openMail() {
      let url = 'mailto:?subject=&body=' // + encodeURIComponent('<hfname>' + this.sharingCode + '</hfname>')
      // window.open(url, '_system')
      document.location = url
    },
  }
}
</script>