const messages = {
  en: {
    menu:{
      'start': 'Start',
      'background': 'Background',
      'personalize': 'Personalize',
      'addons': 'Addons',
      'sharing': 'Sharing',
      'credits': 'credits'
    },
    'start-title': 'Create hyper-personalized images for sales outreach',
    'start-description': 'Don\'t waste time creating videos which go unseen!<br>Create custom video thumbnails which are more likely to be clicked by for videos recorded and hosted on popular tools like Youtube, Zoom, loom and Vimeo.',
    'home-title-1': 'Cold Emails',
    'home-description-1': 'Struggling to get the attention of your prospects? Create a highly personalized image using their photo, company logo & text to stand out in the inbox',
    'home-button-1': 'Create email thumbnail',
    'home-title-2': 'Sales Videos',
    'home-description-2': 'Create custom video thumbnails for your sales videos making them more likely to be clicked - works with popular tools like Youtube, Zoom, Vidyard, Loom and Vimeo.',
    'home-button-2': 'Create video thumbnail',
    'home-title-3': 'Sharing Links',
    'home-description-3': 'Hyperlinks are so 1999. Share documents, web pages, proposals & meeting recordings in a way that make sure they don’t get ignored by the viewers.',
    'home-button-3': 'Create a document thumbnail',
    'mobile-start-title': 'You\'re missing out',
    'mobile-start-description': 'Unfortunately we\'re currently only supporting this thumbnail generator on a desktop computer. Basically because it was too small workspace to create something useful. <br>Try it out next time you\'re on your laptop.',
    'get-started': 'Get Started Now',
    'quick-start-guide': 'Quick Start Guide',
    'video-link-title': 'Enter link to the video',
    'video-link-description': 'We will link the thumbnail to this and grab a frame from the video as background',
    'video-link-note':'Note: You can also try linking to other URLs like websites, Google documents and images',
    'video-link-skip': 'Continue without a link',
    'video-url': 'Video URL (youtube, vimeo, loom, getaccept or similar)',
    'saved-thumbnails-title': 'Reuse your saved thumbnails',
    'open': 'Open',
    'search-person-photo': 'Search for a public photo of a person',
    'no-images-found': 'No images could be found for the search',
    'person-name-company': 'Person name and company',
    'select-company-title': 'Add a company logo or website',
    'select-company-description': 'We will automatically get screenshot and the logo from this website to personalize your thumbnail',
    'select-background': 'Select a background',
    'company-name-domain': 'Company name, domain or URL',
    'website-url-get': 'Get ',
    'loading-website': 'Get website...',
    'loading-video': 'Get video...',
    'loading-logo': 'Get logo...',
    'credits-available': 'thumb credits available',
    'credits-collection': 'Collect credits and use to remove watermarks in your completed thumbnails before sharing.',
    'credits-how-to': 'Ways to collect credits',
    'thumb-credits-added': 'thumb credits were added when',
    'thumb-credits-removed': 'thumb credits were removed when',
    'deselect-image': 'Deselect',
    'forward-image': 'Bring forward',
    'delete-image': 'Delete image',
    'click-to-edit': 'Click an object to resize, remove or move forward',
    'upload-photo-title': 'Upload a photo or use your webcam',
    'upload-photo-description': 'We use <a href="https://towardsdatascience.com/background-removal-with-deep-learning-c4f2104b3157" class="link external" target="_new">AI</a> to remove your background automagically',
    'upload-image': 'Upload photo',
    'upload-processing': 'We\'re processing your upload',
    'upload-done': 'You look awesome!',
    'upload-processing-description': 'It usuallly takes 5-10 seconds to process the image and remove the background.',
    'upload-select-version': 'Now its time to pick your favorite. Click the image and effect that you prefer below:',
    'upload-original': 'Original without background',
    'upload-outlined': 'Outlined version',
    'maximum-filesize': 'Maximum file size is XX',
    'background-not-detected': 'Couldn\'t detect background. Please try again with a smaller photo.',
    'upload-error': 'Could not get any uploaded picture. Please try uploading again or restart/reload the browser.',
    'camera-error': 'Could not get any picture from the webcam. Try to unplug it and connect again or restart/reload the browser.',
    'camera-access-denied': 'Please allow access to the webcamera in your browser and try again.',
    'fetch-url-error': 'Could not fetch URL. Please try again later',
    'video-not-found': 'Video could not be found!',
    'webcam': 'Webcam',
    'photo': 'Photo',
    'show-photo-library': 'Open library',
    'library-empty': 'Your photo library is empty. Upload a photo or take a snapshot with your webcam and it will automatically be added to your library.',
    'photo-library': 'Photo library',
    'delete-image': 'Delete image',
    'background-library-empty': 'Your background library is empty. Upload a background and it will automatically be added to your library.',
    'background-library': 'Background library',
    'delete-background': 'Delete background',
    'upload-background': 'Upload background',
    'show-background-library': 'Open library',
    'use-webcam': 'Use Webcam',
    'add-image-outline': 'Add white image outline',
    'take-photo-title': 'Take photo',
    'retake-photo': 'Retake photo',
    'reupload-photo': 'Upload new',
    'take-photo-description': 'Try to take a good, clean photo of yourself with not too distracting background. The background will automatically be removed after you\'re done with the shot',
    'select-video-source': 'Select camera',
    'add-logo': 'Add logo',
    'add-to-scene': 'Add to scene',
    'basic': 'Basic',
    'fun': 'Fun',
    'custom': 'Custom',
    'text-tab': 'Text',
    'buttons-tab': 'Buttons',
    'stickers-tab': 'Stickers',
    'title-text': 'Title text',
    'short-catchy-title': 'Short catchy title',
    'please-choose': 'Please choose...',
    'font-family': 'Font family',
    'font-style': 'Font style',
    'font-size': 'Font size',
    'font-color': 'Font color',
    'outline-color': 'Outline color',
    'outline-size': 'Outline size',
    'text-align': 'Align text',
    'color': 'Color',
    'search-image': 'Search image',
    'stickers-our-favorites': 'Our favorites',
    'searching': 'Searching',
    'load-more': 'Load more',
    'copy-clipboard': 'Copy image',
    'copy-email': 'Copy for Email',
    'thumbnail-copied': 'Your thumbnail has been copied to your clipboard!',
    'thumbnail-linked': 'The image can be pasted into any email client and is already linked to the video or website link you provided.',
    'thumbnail-image': 'The image can be pasted into places that support images, example LinkedIn, Slack, Messenger.',
    'thumbnail-better': 'Remember if you are trying to use this thumbnail in an email, it is better off using our Copy for Email feature to ensure it can be viewed by the recipient.',
    'paste-link-mac': 'Paste link on Mac OS',
    'paste-link-windows': 'Paste link on Windows',
    'paste-image-mac': 'Paste image on Mac OS',
    'paste-image-windows': 'Paste image on Windows',
    'open-mail-description': 'Open your preferred email client and manually paste the linked image:',
    'open-gmail': 'Open Gmail',
    'open-macmail': 'Open Mac Mail',
    'open-outlook': 'Open Outlook',
    'share-thumbnail': 'Share your thumbnail',
    'share-thumbify': 'Share Thumbify.me with others',
    'share-description': 'Copy and share this link with others to collect 10 credits every time they create thumbnails.',
    'download-project': 'Download',
    'thumbnail-downloaded': 'Thumbnail has been downloaded',
    'remove-watermark': 'Remove watermark (-2)',
    'next': 'Next',
    'close': 'Close',
    'create-new': 'Create a new thumbnail',
    'show-start': 'Home page',
    'show-project-library': 'My thumbnails',
    'save-project': 'Save thumbnail',
    'project-saved': 'Thumbnail saved!',
    'save-enter-name': 'Enter a name to save your thumbnail.',
    'save-enter-email': 'Recover your work in the future by entering your email address or register a profile using your Google or Facebook account below.',
    'project-name': 'Thumbnail name',
    'email': 'E-mail',
    'congratulations': 'Congratulations!',
    'ready-for-sharing': 'Your thumbnail is ready for sharing',
    'quick-edit': 'Quick Edit',
    'unsaved-project': 'Unsaved',
    'modify-link-title': 'Modify thumbnail link',
    'modify-link-description': 'Want to reuse your thumbnail template with a different link? <br>Simply enter the new URL below',
    'remove-project-title': 'Remove',
    'remove-project-confirm': 'Please confirm that you want to remove this thumbnail',
    'credit-collecting': 'Collect XX thumb credits by YY',
    'credit-explanation': 'Look out for these badges to collect more thumb credits and get access to more features',
    'login-register': 'Login / Register',
    'login-link-email': 'An email has been sent with a login link - Please check your inbox and click the link to log in.',
    'signup-link-email': 'Thank you for registering your email! We\'ve just sent you an email with a confirmation link - Please check your inbox and click the link to finalize.',
    'already-registered-google': 'This email has already been registered using Google.',
    'already-registered-facebook': 'This email has already been registered using Facebook.',
    'enter-email-register': 'Enter your email to save your profile',
    'enter-email-signin': 'Enter your email to signup/signin',
    'please-enter-email': 'Please enter your email address',
    'logout': 'Logout',
    'out-of-credits': 'Out of credits',
    'credit-get-title': 'Want more credits?',
    'credit-get-description': 'If you’ve found yourself with no credits don’t worry - below are a few ways to instantly receive more so you can keep busy building your own video thumbnails and remove image backgrounds.',
    'credit-visit-partner-website': 'Visit our partner website',
    'credit-visit-partner-description': 'Follow this web link to our partner of the month and instantly earn 50 Thumbify credits',
    'credit-trial-partner-tool': 'Explore our network of sales tools',
    'credit-trial-partner-description': 'If you liked Thumbify.me, you’ll love GetAccept which uses video to bring proposals, documents and contracts to life. Create a free account & earn 500 Thumbify credits',
    events: {
      'watched-explainer': 'Watching explainer video',
      'created-first-project': 'Creating your first thumbnail',
      'saved-first-project': 'Saving your first thumbnail',
      'saved-email': 'Adding your email address',
      'refer-a-friend': 'Referring a friend',
      'create-five-projects': 'Creating 5 thumbnails',
      'used-webcam': 'Using your webcam to take a photo',
      'add-customized-text': 'Customizing a text',
      'add-company-logo': 'Adding a company logo',
      'collect-credits': 'Using features near these badges',
      'removing-watermark': 'Removing a watermark',
      'removing-background': 'Removing background from image',
      'visit-partner-website': 'Visiting a partner website',
      'trial-partner-tool': 'Sign up for free trial with a partner tool',
    },
  }
}
export { messages }