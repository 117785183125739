<template>
  <f7-popover class="popover-debug">
      <f7-block style="max-height: 30em; overflow: auto">
        <f7-row>
          <f7-col
            ><f7-link
              v-if="currentProject && currentProject.id && user && user.uid"
              :href="
                'https://console.firebase.google.com/u/1/project/' +
                this.apiProjectId +
                '/database/' +
                this.apiProjectId +
                '-default-rtdb/data/~2Fprojects~2F' +
                user.uid +
                '~2F' +
                currentProject.id +
                ''
              "
              external
              target="db"
              >Project DB</f7-link
            ></f7-col
          >
          <f7-col
            ><f7-link
              v-if="currentProject && currentProject.id && user && user.uid"
              :href="
                'https://console.firebase.google.com/u/1/project/' +
                this.apiProjectId +
                '/storage/' +
                this.apiProjectId +
                '.appspot.com/files~2Fi~2F' +
                user.uid +
                '~2F' +
                currentProject.id +
                ''
              "
              external
              target="storage"
              >Project Bucket</f7-link
            ></f7-col
          >
        </f7-row>
        <small>
          <h4>DEBUG INFORMATION</h4>
          <pre v-if="session">
Created: {{ fixDateTime(session.createdAt) }}
Last login: {{ fixDateTime(session.lastLoginAt) }}
</pre
          >
          <pre>
User {{ user }}
Current project {{ currentProject }}
Session {{ session }}
</pre
          >
        </small>
      </f7-block>
    </f7-popover>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    user: Object,
    apiProjectId: String,
    currentProject: Object,
    session: Object
  },
  methods: {
    fixDateTime(datetime) {
      return moment(datetime).format('LLL')
    },
  }
}
</script>