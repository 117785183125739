<template>
  <f7-page name="create-vimeo">
    <f7-card class="main-card content-card" padding>
      <f7-card-content>
        <f7-row class="card-nav">
          <f7-col width="100" medium="30">
            <f7-link href="/">
              <img
                src="static/images/logo.svg"
                class="logo"
                alt="thumbify.me logo"
              />
            </f7-link>
          </f7-col>
        </f7-row>
        <f7-block class="content-page">
          <f7-row class="text-align-center">
            <f7-col>
              <h1>How to Make a Vimeo Video Thumbnail</h1>
              <h2>A guide to creating perfect video thumbnails for Vimeo</h2>
              <img
                src="static/images/blog/how-to-make-a-vimeo-video-thumbnail.png"
                alt="How to Make a Vimeo Video Thumbnail"
                width="100%"
                loading="lazy"
              />

              <f7-button fill large class="start-button" href="/new">
                Create your Free Vimeo thumbnail
                <f7-icon icon="icon-right"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <p>
                Creating a thumbnail in Vimeo can be hard for creators of all
                abilities - with digital editing &amp; software tools
                introducing too many features into what should be an easy
                process.
              </p>
              <p>
                In this guide, we will look at how you can create a captivating
                Vimeo video thumbnail from scratch using Thumbify.me
              </p>

              <h3>Determining the Purpose for Your Video Thumbnail</h3>
              <p>
                The goal of any video is to make sure as many people view the
                content as possible. The video thumbnail is the most important
                aspect of driving viewer behavior in conjunction with having a
                catchy title.
              </p>

              <p>
                Your video thumbnail should have a clear purpose but this can
                often be hard to pin down without a structured process. To help,
                we have drawn up a list of questions which you can ask yourself
                to come to an answer of who or what your video thumbnail should
                be targeting:
              </p>
              <ul>
                <li>
                  Which scene am I trying to draw my viewer to watch via the
                  video thumbnail?
                </li>
                <li>
                  Are there any pivotal moments, images or text I can include in
                  my video thumbnail to make it stand out?
                </li>
                <li>
                  Can I experiment with images or references people already know
                  to receive secondary traffic from those with an interest in
                  this content? I.e. If you are creating a video about beauty
                  you could include an image of Fabio, the most handsome male
                  model of all time
                </li>
              </ul>
              <p>Gathering What You Need to Create Your Video Thumbnail</p>
              <p>
                Once you have solidified the vision &amp; purpose for your video
                thumbnail you will need to prepare a few of the following
                elements:
              </p>
              <p>
                <b>Background:</b> What image would you like for everything to
                be above in your thumbnail?
              </p>
              <p>
                <b>Foreground:</b> What images do you have, without backgrounds,
                that you can use to design and add character to the scene in
                your video thumbnail?
              </p>
              <p>
                <b>Your image:</b> Do you have a nice image of yourself,
                remembering that this will need to change as thumbnails that
                look the same will have less chance of being clicked
              </p>
              <p>
                <b>Text and title:</b> What do you plan to express to the viewer
                on the video thumbnail to encourage them to click and watch the
                content?
              </p>
              <p>
                <b>Existing Thumbnail:</b> Perhaps you want to keep your video
                thumbnail as simple as possible and simply wish to edit on top
                of the thumbnail already generated by Vimeo when you upload your
                video <br /><br />Thankfully, Thumbify.me takes care of all of
                the above and more which we will go into detail about below
              </p>

              <iframe
                width="100%"
                src="https://www.youtube.com/embed/YEO7MC4I4DE?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                loading="lazy"
                title="Video of How to Make a Vimeo Video Thumbnail"
              ></iframe>

              <h3>Creating your Vimeo Video Thumbnail</h3>
              <p>
                Open up the Start section on Thumbify.me and paste the link to
                the Vimeo video you have uploaded.
              </p>
              <p>
                You will see that we instantly grab the video thumbnail for your
                project
              </p>

              <h4>Selecting a Different Background</h4>
              <p>
                Within the Background section, you can choose from a variety of
                different backgrounds by exploring the Basic and Fun tabs to see
                our pre-existing templates.
              </p>
              <p>
                If you would like to upload your own background - click custom,
                followed by the Upload Background button and you can select the
                file you would like to use for your video thumbnail
              </p>

              <h4>Adding Images to Your Video Thumbnail</h4>
              <p>
                Once you proceed to the Personalize section of Thumbify.me you
                will then have three options you can use to add images to your
                video thumbnails.
              </p>
              <p>
                The first is the upload photo option, which will allow you to
                select any photo and our AI engine will automatically remove the
                background.
              </p>
              <p>
                The AI will also give you the option of inserting the image with
                or without a white outline, which is now very common amongst top
                rating Vimeo video thumbnails. Also note that your first image
                will take longer to render than the images which follow. We also
                recommend using high resolution pictures and images where the
                subject clearly stands out from the background so you achieve
                the best results.
              </p>
              <p>
                The second is the webcam option, which will allow you to follow
                the same steps above except using a live image of yourself.
                Experiment with the timer and used the delayed shot to strike a
                pose.
              </p>
              <p>
                The third option is to open the image library - which has all of
                your previously uploaded images and webcam snapshots ready to
                use, retaining the removed background to save you time.
              </p>

              <h4>Company or Website Search Feature</h4>
              <p>
                Before leaving the Personalize section of Thumbify.me - insert
                website screenshots or logos from top brands by using our
                company search function. This feature is especially helpful for
                use cases like corporate Vimeo videos or for videos made about
                trending topics. Once your search is complete, you can easily
                add the logo or website to your thumbnail and resize it
                accordingly
              </p>

              <h4>Finishing Touches for Your Vimeo Thumbnail</h4>
              <p>
                In the final section of Thumbify.me, you can play with with a
                number of add-ons like adding buttons to your video thumbnail,
                creating text and placing stickers on your thumbnail project.
              </p>
              <p>
                When adding text, we have sourced most of the most common fonts
                used to create Vimeo video thumbnails.
              </p>
              <p>
                In the sticker area, we have listed many popular options, which
                work best when added on top of faces. However, you can use the
                search function to explore several thousand images for your
                foreground which are royalty free and have had their backgrounds
                removed
              </p>

              <h4>Saving and Downloading Your Vimeo Thumbnail</h4>
              <p>
                To download your Vimeo thumbnail, proceed to the Saving screen of Thumbify.me and press download to receive a high resolution version of your thumbnail. 

              </p>
              <p>
If you would like to use the same template again in the future, don’t forget to save the project and enter your email address to avoid losing your work. 
              </p>
              <p>
If you would like feedback on your design prior to going live, use the copy image and link button to copy the image onto your clipboard and send via popular email clients like Outlook, Gmail and Safari
              </p>
              <f7-button fill large class="start-button" href="/new">
                Make your Vimeo Thumbnail now
                <f7-icon icon="icon-right"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>

          <div class="content-credit">
            <f7-link
              href="https://salesresearchlabs.com"
              external
              target="_system"
              class="footer-credit"
            >
              Made with <f7-icon icon="icon-heart"></f7-icon> by Sales Research
              Labs
            </f7-link>
          </div>
        </f7-block>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
export default {
  created() {
    let title = 'Thumbify.me - How to Make a Vimeo Video Thumbnail'
    let metakeywords = 'video thumbnail generator, video thumbnail generator vimeo, vimeo,  video thumbnail maker, video thumbnail maker for vimeo, video thumbnail maker vimeo, vimeo video thumbnail creator, vimeo video thumbnail, free vimeo thumbnail maker, free vimeo thumbnail generator, free vimeo thumbnail creator, creating a vimeo video thumbnail, how to create a video thumbnail, vimeo video thumbnail guide, vimeo video thumbnail tips, how to make a video thumbnail for vimeo, how to create a video thumbnail for vimeo, how to make a vimeo video thumbnail, how to create a vimeo video thumbnail'
    let metadescription = 'Making a Vimeo Thumbnail is easy - follow our guide on how to create a stunning Vimeo video thumbnail'
    document.title = title
    document.head.querySelector('meta[name="description"]').setAttribute('content', metadescription)
    document.head.querySelector('meta[name="keywords"]').setAttribute('content', metakeywords)
  }
}
</script>