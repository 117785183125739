<template>
  <f7-list-item
    checkbox
    readonly
    disabled
    :checked="checked"
    :title="$t('events.' + index)"
    :after="title"
  ></f7-list-item>
</template>
<script>
export default {
  props: {
    index: String,
    checked: Boolean,
    title: Number
  },
  methods: {

  }
}
</script>