<template>
  <f7-popup
      class="popup-login"
      swipe-to-close="to-bottom"
      swipe-handler=".swipe-handler"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-title> {{ $t("login-register") }} </f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close :tooltip="$t('close')">
              <f7-icon icon="icon-close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block-title medium>Hello</f7-block-title>
        <f7-block>
          <p>We temporarily keep the projects you work on here on the site.</p>
          <p>
            If you would like to make your work private and restore it in the
            future, sign up or register with your email below.
          </p>
        </f7-block>
        <f7-list
          no-hairlines
          no-hairlines-between
        >
          <f7-list-input
            floating-label
            outline
            label="E-mail"
            type="email"
            :placeholder="$t('enter-email-signin')"
            :value="emailForSignIn"
            @change="emailForSignIn = $event.target.value"
          >
          </f7-list-input>
        </f7-list>

        <f7-block class="text-align-center">
          <f7-row>
            <f7-col width="100">
              <f7-button
                id="signupButton"
                class="signupButton"
                icon="icon-user"
                @click="popupSignInWithEmail"
                v-text="$t('login-register')"
              >
              </f7-button>
            </f7-col>
          </f7-row>

          <f7-row>
            <f7-col width="100">
              <f7-block> or use </f7-block>
            </f7-col>
          </f7-row>

          <f7-row class="signin-external">
            <f7-col width="15"></f7-col>
            <f7-col width="33">
              <f7-button @click="popupGoogleSignIn()">
                <img src="static/images/google.svg" />
                <span>Google</span>
              </f7-button>
            </f7-col>
            <f7-col width="33">
              <f7-button @click="popupFacebookSignIn()">
                <img src="static/images/facebook.svg" />
                <span>Facebook</span>
              </f7-button>
            </f7-col>
            <f7-col width="15"></f7-col>
            <!-- <f7-col width="33">
              <f7-button @click="appleSignIn">
                <img src="static/images/apple.svg" />
                <span>Apple</span>
              </f7-button>
            </f7-col> -->
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>
</template>
<script>
export default {
  data() {
    return {
      emailForSignIn: null
    }
  },
  props: {
  },
  methods: {
    popupSignInWithEmail(){
      this.$emit('signInWithEmail', this.emailForSignIn)
    },
    popupGoogleSignIn() {
      this.$emit('googleSignIn')
    },
    popupFacebookSignIn() {
      this.$emit('facebookSignIn')
    }
  }
}
</script>