<template>
  <f7-page name="create-video">
    <f7-card class="main-card content-card" padding>
      <f7-card-content>
        <f7-row class="card-nav">
          <f7-col width="100" medium="30">
            <f7-link href="/">
              <img
                src="static/images/logo.svg"
                class="logo"
                alt="thumbify.me logo"
              />
            </f7-link>
          </f7-col>
        </f7-row>
        <f7-block class="content-page">
          <f7-row class="text-align-center">
            <f7-col>
              <h1>How to Make a Video Thumbnail</h1>
              <h2>A guide to creating perfect video thumbnails</h2>
              <img
                src="static/images/blog/how-to-make-a-video-thumbnail.png"
                alt="How to Make a Video Thumbnail"
                width="100%"
                loading="lazy"
              />

              <f7-button fill large class="start-button" href="/new">
                Create your Free Video thumbnail
                <f7-icon icon="icon-right"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <p>
                Creating a thumbnail can be hard for creators of all abilities -
                with many solutions just overcomplicating what should be a one
                minute process.
              </p>
              <p>
                In this guide, we will look at why video thumbnails are
                important and who you can create one quickly &amp; beautifully
                from scratch using Thumbify.me
              </p>

              <h3>What is a video thumbnail?</h3>
              <p>
                A thumbnail is a small image, rendered from a video or designed
                from scratch, that visually represents the content of the video.
                You may think the term ‘thumbnail’ is strange - but this isn’t
                just you! The term was invented because early video hosting
                websites showed the first frame of the video as a preview in a
                tiny box, so small in fact it represented a human finger nail.
                Now, when a viewer hovers on a thumbnail - most video hosting
                providers like Youtube or Vimeo offer a moving preview of the
                video content or begin auto-playing the content.
              </p>

              <h3>Elements of a Good Video Thumbnail</h3>

              <p>
                The goal of any video is to make sure as many people view the
                content as possible. The video thumbnail is the most important
                aspect of driving viewer behavior in conjunction with having a
                catchy title.
              </p>
              <p>
                <b>1. Targets the Most Important Scene in the Video</b><br />
                The best video thumbnails feature the scene which is going to
                resonate most with the audience. If we think of an analogy, the
                thumbnail should hint at the major plot point without giving
                away the secrets!
              </p>
              <p>
                <b>2. Uses a combination of text and images to stand out </b
                ><br />
                Many creators think they need to use images they themselves have
                taken. However most successful thumbnails leverage popular
                images like memes or celebrity photos to draw the viewer in
              </p>
              <p>
                <b>3. A clear image of the subject of the video</b><br />
                Most video thumbnails will be promoting a video where the
                subject is a person, especially with the rise of blogging. In
                this case, creating multiple images of yourself to use in
                different video thumbnails can be a challenge, so we attempted
                to solve this with our solution Thumbify.me, which transforms
                your webcam images instantly into background-less photos to use
                on your video thumbnail
              </p>
              <p>
                <b>4. Can change over time</b><br />
                Occasionally, a video may not perform in terms of views and a
                creator can simply change the thumbnail and watch as views
                skyrocket. In this case, you need to be open to experimenting
                with your thumbnails to make sure you are leveraging available
                traffic
              </p>

              <iframe
                width="100%"
                src="https://www.youtube.com/embed/5wk-STCI0h0?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                loading="lazy"
                title="Video of How to Make a Video Thumbnail"
              ></iframe>

              <h3>What size should my video thumbnail be?</h3>
              <p>
                The thumbnail size most commonly accepted on platforms like
                Youtube and Vimeo has is a resolution of 1280×720 pixels, and
                the file should be under 2MB. You will also find that anything
                composed in a 16:9 ratio will also work and then the image will
                be upscaled or downscaled accordingly.
              </p>

              <h4>Gathering What You Need to Create Your Video Thumbnail</h4>
              <p>
                Once you have solidified the vision &amp; purpose for your video
                thumbnail you will need to prepare a few of the following
                elements:
              </p>

              <p>
                <b>Background:</b> What image would you like for everything to
                be above in your thumbnail?
              </p>
              <p>
                <b>Foreground:</b> What images do you have, without backgrounds,
                that you can use to design and add character to the scene in
                your video thumbnail?
              </p>
              <p>
                <b>Your image:</b> Do you have a nice image of yourself,
                remembering that this will need to change as thumbnails that
                look the same will have less chance of being clicked
              </p>
              <p>
                <b>Text and title:</b> What do you plan to express to the viewer
                on the video thumbnail to encourage them to click and watch the
                content?
              </p>
              <p>
                <b>Existing Thumbnail:</b> Perhaps you want to keep your video
                thumbnail as simple as possible and simply wish to edit on top
                of the thumbnail already generated by the video recording
                software when you upload your video <br /><br />Thumbify.me
                takes care of all of the above and more which we will go into
                detail about below
              </p>

              <h3>Creating your Video Thumbnail</h3>
              <h4>Connecting your Video</h4>
              <p>
                Open up the Start section on Thumbify.me and paste the link to
                the video you have uploaded to any of the major providers.
              </p>
              <p>
                You will see that we instantly grab the video thumbnail for your
                project however if we don’t you can resolve this in the next
                step.
              </p>
              <h4>Selecting a Different Background</h4>
              <p>
                Within the Background section, you can choose from a variety of
                different backgrounds by exploring the Basic and Fun tabs to see
                our pre-existing templates.
              </p>
              <p>
                If you would like to upload your own background - click custom,
                followed by the Upload Background button and you can select the
                file you would like to use for your video thumbnail
              </p>

              <h4>Adding Images to Your Video Thumbnail</h4>
              <p>
                Once you proceed to the Personalize section of Thumbify.me you
                will then have three options you can use to add images to your
                video thumbnails.
              </p>
              <p>
                The first is the upload photo option, which will allow you to
                select any photo and our AI engine will automatically remove the
                background.
              </p>
              <p>
                The AI will also give you the option of inserting the image with
                or without a white outline, which is now very common amongst top
                rating video thumbnails across a range of platforms. Also note
                that your first image will take longer to render than the images
                which you work on next due to our server configuration. We also
                recommend using high resolution pictures and images where the
                subject clearly stands out from the background so you achieve
                the best results.
              </p>
              <p>
                The second is the webcam option, which will allow you to follow
                the same steps above except using a live image of yourself.
                Experiment with the timer and used the delayed shot to strike a
                pose.
              </p>
              <p>
                The third option is to open the image library - which has all of
                your previously uploaded images and webcam snapshots ready to
                use, retaining the removed background to save you time.
              </p>

              <h4>Company or Website Search Feature</h4>
              <p>
                Before leaving the Personalize section of Thumbify.me - insert
                website screenshots or logos from top brands by using our
                company search function. This feature is especially helpful for
                use cases like corporate videos or for videos made about
                trending topics. Once your search is complete, you can easily
                add the logo or website to your thumbnail and resize it
                accordingly
              </p>
              <h4>Finishing Touches for Your Video Thumbnail</h4>
              <p>
                In the final section of Thumbify.me, you can play with with a
                number of add-ons like adding buttons to your video thumbnail,
                creating text and placing stickers on your thumbnail project.
              </p>
              <p>
                When adding text, we have sourced most of the most common fonts
                used to create video thumbnails on the major platforms.
              </p>
              <p>
                In the sticker area, we have listed many popular options, which
                work best when added on top of faces. However, you can use the
                search function to explore several thousand images for your
                foreground which are royalty free and have had their backgrounds
                removed
              </p>

              <h4>Saving and Downloading Your Video Thumbnail</h4>
              <p>
                To download your video thumbnail, proceed to the Saving screen
                of Thumbify.me and press download to receive a high resolution
                version of your thumbnail.
              </p>
              <p>
                If you would like to use the same template again in the future,
                don’t forget to save the project and enter your email address to
                avoid losing your work.
              </p>
              <p>
                If you would like feedback on your design prior to going live,
                use the copy image and link button to copy the image onto your
                clipboard and send via popular email clients like Outlook, Gmail
                and Safari
              </p>
              <h3>Other tips for creating great video thumbnails:</h3>
              <ul>
                <li>
                  Don’t use too many elements - too many images, too much text
                  or too many messages can cause confusion for the viewer. Try
                  and create something united in concept and purpose
                </li>
                <li>
                  People like faces - research has shown thumbnails including
                  the face of a person experience better click throughs
                </li>
                <li>
                  Don’t ignore color - the colors you choose can dramatically
                  affect your video performance. Think about avoiding dark
                  colours like black and look for bright, happy colours like
                  green &amp; blue
                </li>
                <li>
                  Try A/B testing - where possible test your image amongst a
                  group of people and run with the image that converts better
                </li>
                <li>
                  Don’t forget to add a play button which you can do with
                  Thumbify.me - people may not think your video is a video -
                  ensure they click it everytime!
                </li>
              </ul>
              <f7-button fill large class="start-button" href="/new">
                Make your Video Thumbnail now
                <f7-icon icon="icon-right"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>

          <div class="content-credit">
            <f7-link
              href="https://salesresearchlabs.com"
              external
              target="_system"
              class="footer-credit"
            >
              Made with <f7-icon icon="icon-heart"></f7-icon> by Sales Research
              Labs
            </f7-link>
          </div>
        </f7-block>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
export default {
  created() {
    let title = 'Thumbify.me - How to Make a Video Thumbnail'
    let metakeywords = 'video thumbnail generator,  video thumbnail maker, video thumbnail creator, video thumbnail, free thumbnail maker, free video thumbnail maker, free video thumbnail generator, free video thumbnail creator, creating a video thumbnail, how to create a video thumbnail, video thumbnail guide, video thumbnail tips, how to make a video thumbnail'
    let metadescription = 'Making a Video Thumbnail has never been easier - follow this guide on how to create a stunning video thumbnail'
    document.title = title
    document.head.querySelector('meta[name="description"]').setAttribute('content', metadescription)
    document.head.querySelector('meta[name="keywords"]').setAttribute('content', metakeywords)
  }
}
</script>