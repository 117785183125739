<template>
  <f7-popup
    class="popup-webcam"
    swipe-to-close="to-bottom"
    swipe-handler=".swipe-handler"
    @popup:close="stopCamera"
    @popup:open="initCamera"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t("take-photo-title") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close :tooltip="$t('close')">
            <f7-icon icon="icon-close"></f7-icon>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <p>{{ $t("take-photo-description") }}</p>
      </f7-block>
      <f7-list no-hairlines no-hairlines-between>
        <f7-list-input
          :label="$t('select-video-source')"
          type="select"
          :placeholder="$t('please-choose')"
          @change="setCamera"
        >
          <option
            v-for="device in devices"
            :key="device.deviceId"
            :value="device.deviceId"
            :selected="device.deviceId == deviceId"
          >
            {{ device.label }}
          </option>
        </f7-list-input>
        <f7-list-item>
          <vue-web-cam
            ref="webcam"
            :device-id="deviceId"
            v-if="webcamLoaded"
            height="360"
            :selectFirstDevice="false"
            @started="onStarted"
            @stopped="onStopped"
            @error="onCameraError"
            @cameras="onCameras"
            @camera-change="onCameraChange"
          />
        </f7-list-item>
        <f7-list-item class="webcam-controls">
          <f7-button
            icon="icon-camera"
            @click="takePicture()"
            v-show="snapCountdown == 4"
          ></f7-button>
          <f7-button
            class="countdown"
            @click="delayedPicture()"
            v-show="snapCountdown == 4"
            v-text="snapCountdown - 1 + 's'"
          ></f7-button>
        </f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
export default {
  data() {
    return {
      camera: null,
      devices: [],
      deviceId: null,
      snapCountdown: 4,
      webcamLoaded: false,
      started: []
    }
  },
  props: {
  },
  methods: {
    /* Webcam methods */
    setCamera(e) {
      // console.log('setCamera',e.target.value)
      // this.$refs.webcam.stop()
      this.deviceId = e.target.value
    },
    delayedPicture() {
      this.snapCountdown--
      this.$f7.toast.create({
        text: 'Ready',
        position: 'center',
        closeTimeout: 700,
        closeButton: false,
      }).open()
      let countdown = setInterval(() => {
        this.$f7.toast.create({
          text: this.snapCountdown,
          position: 'center',
          closeTimeout: this.snapCountdown > 0 ? 700 : 300,
          closeButton: false,
        }).open()
        this.snapCountdown--
        if (this.snapCountdown == -1) {
          this.takePicture()
          clearInterval(countdown)
        }
      }, 1000)

    },
    takePicture() {
      function dataURItoBlob(dataURI) {
        var byteString = atob(dataURI.split(',')[1])
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length)
        var ia = new Uint8Array(ab)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        var bb = new Blob([ab], { "type": mimeString })
        return bb
      }

      // this.$refs.webcam.capture()
      let imgData = this.$refs.webcam.capture()
      let blob = dataURItoBlob(imgData)
      this.$refs.webcam.stop()
      this.$emit('uploadWebcam', imgData, blob)
    },
    onStarted(stream) {
      // console.log("On Started Event", stream)
      this.started.push(stream)
    },
    onStopped(stream) {
      // console.log("On Stopped Event", stream)
    },
    initCamera() {
      this.webcamLoaded = true
      this.snapCountdown = 4
      this.startCamera()
    },
    startCamera() {
      if (this.$refs.webcam) {
        this.$refs.webcam.start()
      }
    },
    stopCamera() {
      // console.log('stopCamera')
      // if (this.$refs.webcam) {
      // this.$refs.webcam.stop()
      // }
      if (this.started) {
        this.started.forEach(stream => {
          // console.log('stopping stream ', stream.id)
          let tracks = stream.getTracks()
          tracks.forEach(track => {
            track.stop()
          })
        })
      }

    },
    onCameraError(err) {
      console.log("Camera err", err)
      let msg
      if (err.indexOf(' denied') > -1) {
        msg = this.$t('camera-access-denied')
      }
      if (msg) {
        this.$f7.toast.create({
          icon: '<i class="icon-warning"></i>',
          text: error.message,
          position: 'center',
          cssClass: 'warning',
          closeTimeout: 6000,
          closeButton: true,
        }).open()
      }
    },
    onCameras(cameras) {
      // this.devices = cameras
      // Figure out best webcam
      // cameras.forEach(device => {
      //   if (!this.deviceId && (device.label.indexOf('Webcam') > -1 || device.label.indexOf('HD ') > -1)) {
      //     this.camera = device.deviceId
      //     this.deviceId = device.deviceId
      //     console.log('starting first found camera')
      //     return
      //     // setTimeout(()=>{
      //     //   this.$refs.webcam.start()
      //     // },100)
      //   }
      // })

      // Reorder list
      cameras.sort((a, b) => {
        if (a.label && a.label.indexOf('HD Pro') > -1) {
          return -1
        }
        return 0
      })
      this.devices = cameras

      // console.log("On Cameras Event", cameras)
      if (!this.deviceId && cameras.length > 0) {
        this.deviceId = cameras[0].deviceId
      }

      if (!this.camera && this.deviceId) {
        //Preload chosen camera
        this.camera = this.deviceId
        // this.$refs.webcam.start()
      }
    },
    onCameraChange(deviceId) {
      // console.log('onCameraChange')
      this.deviceId = deviceId
      this.camera = deviceId
      this.$refs.webcam.start()
      // console.log("On Camera Change Event", deviceId)
    },
  }
}
</script>