<template>
  <f7-page name="create-youtube">
    <f7-card class="main-card content-card" padding>
      <f7-card-content>
        <f7-row class="card-nav">
          <f7-col width="100" medium="30">
            <f7-link href="/">
              <img
                src="static/images/logo.svg"
                class="logo"
                alt="thumbify.me logo"
              />
            </f7-link>
          </f7-col>
        </f7-row>
        <f7-block class="content-page">
          <f7-row class="text-align-center">
            <f7-col>
              <h1>How to Make a Youtube Video Thumbnail</h1>
              <h2>A guide to creating perfect video thumbnails for Youtube</h2>
              <img
                src="static/images/blog/how-to-make-a-youtube-video-thumbnail.png"
                alt="How to Make a Youtube Video Thumbnail"
                width="100%"
                loading="lazy"
              />

              <f7-button fill large class="start-button" href="/new">
                Create your Free YouTube thumbnail
                <f7-icon icon="icon-right"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col>
              <p>
                Creating a Youtube Thumbnail can be a daunting task for novice
                and experienced creators alike - with digital editing &amp;
                software tools overcomplicating what should be an easy process
                with their vast range of features and effects.
              </p>
              <p>
                In this guide, we will look at how you can create a Youtube
                Video Thumbnail from scratch using Thumbify.me
              </p>

              <h3>Determining the Purpose for Your Video Thumbnail</h3>
              <p>
                The goal of any video we make is to receive as many eyeballs as
                possible viewing the content. The video thumbnail is possibly
                the most important aspect of driving viewer demand next to
                having a catchy title.
              </p>

              <p>
                Your video thumbnail should have a clear purpose which you can
                brainstorm using the following questions:
              </p>
              <ul>
                <li>
                  What scene am I trying to draw my viewer to watch via the
                  video thumbnail?
                </li>
                <li>
                  Are there any shocking, amazing or inspiring images or text I
                  can include in my video thumbnail?
                </li>
                <li>
                  Can I experiment with images or references people already know
                  to receive secondary traffic from those with an interest in
                  this content?
                </li>
              </ul>
              <p>Gathering What You Need to Create Your Video Thumbnail</p>
              <p>
                Once you have determined the purpose for your video thumbnail
                you will need to gather a few necessary digital materials
              </p>
              <p>
                <b>Background:</b> What image would you like for everything to
                rest upon in your thumbnail?
              </p>
              <p>
                <b>Foreground:</b> What images do you have, ideally without
                backgrounds, that you can use to design the scene in your video
                thumbnail?
              </p>
              <p>
                <b>Your image:</b> Do you have a nice image of yourself,
                remembering that this will need to change over time to avoid
                users seeing too much of the same if you are planning to release
                more than one video?
              </p>
              <p>
                <b>Text and title:</b> What do you plan to say to the viewer on
                the video thumbnail to encourage them to click?
              </p>
              <p>
                <b>Existing Thumbnail:</b> Perhaps you want to keep your video
                thumbnail simple and edit on top of and around the standardized
                thumbnail generated when you upload your video
                <br /><br />Thankfully, Thumbify.me takes care of all of the
                above which we discuss below
              </p>

              <iframe
                width="100%"
                src="https://www.youtube.com/embed/WmNH88nUBBY?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                loading="lazy"
                title="Video of How to Make a Youtube Video Thumbnail"
              ></iframe>

              <h3>Creating your Youtube Video Thumbnail</h3>
              <p>
                Open up the Start section on Thumbify.me and paste the link to
                the video you have uploaded.
              </p>
              <p>
                You will see that we instantly fetch the video thumbnail for
                your canvas
              </p>

              <h4>Selecting a Different Background</h4>
              <p>
                Within the Background section, you can now choose a different
                background by exploring the Basic and Fun tabs to see our
                pre-existing templates.
              </p>
              <p>
                If you would like to upload your own - click custom, followed by
                the Upload Background button and you can select the file you
                would like to use for your video thumbnail
              </p>

              <h4>Adding Images to Your Video Thumbnail</h4>
              <p>
                Once you proceed to the Personalize section of Thumbify.me you
                will then have three options you can use to add images to your
                video thumbnails.
              </p>
              <p>
                The first is the upload photo option, which will allow you to
                select any photo and our AI engine will automatically remove the
                background, giving you the option of inserting the image with or
                without a white outline which has become a common trend in
                Youtube video thumbnails. Also note that your first image will
                take longer to render than subsequent images due to our server
                configuration and that you will achieve the best results with
                high resolution pictures where the subject clearly stands out
                from the background.
              </p>
              <p>
                The second is the webcam option, which will allow you to repeat
                the same process using a live image. Experiment with the timer
                to allow yourself adequate time to strike a pose.
              </p>
              <p>
                The third option is to open the library - which has all of your
                previously uploaded images and webcam snapshots ready to use.
              </p>

              <h4>Company or Website Search Feature</h4>
              <p>
                Before leaving the Personalize section of Thumbify.me - insert
                website screenshots or logos from popular brands by using our
                company search function. This feature is especially helpful for
                use cases like corporate Youtube videos or news content on
                trending topics. Once your search is complete, you can easily
                add the logo or website and resize it accordingly
              </p>

              <h4>Finishing Touches for Your Youtube Thumbnail</h4>
              <p>
                In the final section of Thumbify.me, you can experiment with a
                host of add-ons like adding buttons to your video thumbnail,
                creating text and placing stickers on your canvas.
              </p>
              <p>
                When adding text, we have sourced most of the most common fonts
                used to create Youtube video thumbnails.
              </p>
              <p>
                For stickers, we have listed many of the user favorites to add
                particularly to faces but if you use the search function you can
                explore several thousand images for your foreground which are
                royalty free and automatically have the backgrounds removed
              </p>

              <video autoplay loop muted>
                <source
                  src="static/video/adding-text-buttons-stickers-youtube-video-thumbnail.mp4"
                  type="video/mp4"
                />
              </video>

              <h4>Saving and Downloading Your Youtube Thumbnail</h4>
              <p>
                To download your Youtube thumbnail, proceed to the Saving screen
                of Thumbify.me and press download to receive a high resolution
                version of your thumbnail.
              </p>
              <p>
                If you would like to use the same template again in the future,
                don’t forget to save the project and enter your email address to
                avoid losing your work.
              </p>
              <p>
                If you would like feedback on your design prior to going live,
                use the copy image and link button to copy the image onto your
                clipboard and send it via popular email clients like Outlook,
                Gmail and Safari
              </p>
              <f7-button fill large class="start-button" href="/new">
                Start making your Thumbnail now
                <f7-icon icon="icon-right"></f7-icon>
              </f7-button>
            </f7-col>
          </f7-row>

          <div class="content-credit">
            <f7-link
              href="https://salesresearchlabs.com"
              external
              target="_system"
              class="footer-credit"
            >
              Made with <f7-icon icon="icon-heart"></f7-icon> by Sales Research
              Labs
            </f7-link>
          </div>
        </f7-block>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
export default {
  created() {
    let title = 'Thumbify.me - How to Make a Youtube Video Thumbnail'
    let metakeywords = 'video thumbnail generator, video thumbnail generator youtube, youtube,  video thumbnail maker, video thumbnail maker for youtube, video thumbnail maker youtube, youtube video thumbnail creator, youtube video thumbnail, free youtube thumbnail maker, free youtube thumbnail generator, free youtube thumbnail creator, creating a youtube video thumbnail, how to create a video thumbnail, youtube video thumbnail guide, youtube video thumbnail tips, how to make a video thumbnail for youtube, how to create a video thumbnail for youtube, how to make a youtube video thumbnail, how to create a youtube video thumbnail'
    let metadescription = 'Making a Youtube Thumbnail is easy - follow our guide on how to create a stunning Youtube video thumbnail'
    document.title = title
    document.head.querySelector('meta[name="description"]').setAttribute('content', metadescription)
    document.head.querySelector('meta[name="keywords"]').setAttribute('content', metakeywords)
  }
}
</script>