var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-popover',{staticClass:"popover-debug"},[_c('f7-block',{staticStyle:{"max-height":"30em","overflow":"auto"}},[_c('f7-row',[_c('f7-col',[(_vm.currentProject && _vm.currentProject.id && _vm.user && _vm.user.uid)?_c('f7-link',{attrs:{"href":'https://console.firebase.google.com/u/1/project/' +
                this.apiProjectId +
                '/database/' +
                this.apiProjectId +
                '-default-rtdb/data/~2Fprojects~2F' +
                _vm.user.uid +
                '~2F' +
                _vm.currentProject.id +
                '',"external":"","target":"db"}},[_vm._v("Project DB")]):_vm._e()],1),_vm._v(" "),_c('f7-col',[(_vm.currentProject && _vm.currentProject.id && _vm.user && _vm.user.uid)?_c('f7-link',{attrs:{"href":'https://console.firebase.google.com/u/1/project/' +
                this.apiProjectId +
                '/storage/' +
                this.apiProjectId +
                '.appspot.com/files~2Fi~2F' +
                _vm.user.uid +
                '~2F' +
                _vm.currentProject.id +
                '',"external":"","target":"storage"}},[_vm._v("Project Bucket")]):_vm._e()],1)],1),_vm._v(" "),_c('small',[_c('h4',[_vm._v("DEBUG INFORMATION")]),_vm._v(" "),(_vm.session)?_c('pre',[_vm._v("Created: "+_vm._s(_vm.fixDateTime(_vm.session.createdAt))+"\nLast login: "+_vm._s(_vm.fixDateTime(_vm.session.lastLoginAt))+"\n")]):_vm._e(),_vm._v(" "),_c('pre',[_vm._v("User "+_vm._s(_vm.user)+"\nCurrent project "+_vm._s(_vm.currentProject)+"\nSession "+_vm._s(_vm.session)+"\n")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }