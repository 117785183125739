<template>
  <f7-card
    :style="
      'background-image:url(' +
      (item.thumbUrl ? item.thumbUrl : './static/images/bg.png') +
      ')'
    "
  >
    <f7-link class="card-link" @click="selectBackgroundImage(item)">
      <f7-card-content> </f7-card-content>
    </f7-link>
    <f7-card-footer>
      <f7-link @click="selectBackgroundImage(item)" style="width: 100%">
        <div class="type">{{ item.fileTitle ? item.fileTitle : '' }}</div>
        <div class="date">{{ fixShortDate(item.createdAt) }}</div>
      </f7-link>
      <f7-link
        @click="deleteBackgroundImage(item)"
        icon="icon-trash"
        :tooltip="$t('delete-background')"
      ></f7-link>
    </f7-card-footer>
  </f7-card>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    item: Object
  },
  methods: {
    fixShortDate(datetime) {
      return moment(datetime).format('LL')
    },
    selectBackgroundImage(item) {
      this.$emit('selectBackgroundImage', item)
    },
    deleteBackgroundImage(item) {
      this.$emit('deleteBackgroundImage', item)
    },
  }
}
</script>